import React from 'react';
import './App.css';
import { history } from './app/store';
import { Dialog } from './components/dialog/dialog';
import { Initializer } from './components/init/initializer';
import { Routes } from 'react-router';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { RoutesConfig } from './routes/routes';
import { Route } from 'react-router-dom';

function App() {
  return (
    <>
      <ReduxRouter history={history}>
        <RoutesConfig />
        <Routes>
          <Route
            path={'/*'}
            element={<Initializer />}
          />
        </Routes>
      </ReduxRouter>
      <Dialog />
    </>
  );
}

export default App;
