import con from '../../../../../connection';

const path = '/files';

const files = (url: string) => (file_id?: string) => ({
  // 物件ファイルダウンロード
  get: (param: {
    file_id: string[],
  }) => con.getFile<{
    header: {
      status: string,
      status_code: number,
      messages: { message: string },
    },
    body: {
      data: { file_id: string },
    }
  }>(`${url}${path}`, param),
  // 物件ファイルアップロード
  post: (param: {
    Filedata: File,
  }) => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: { message: string },
    },
  }>(`${url}${path}`, param),
  // 物件ファイル削除
  delete: () => con.delete<{
    header: {
      status: string,
      status_code: number,
      messages: { message: string },
    },
  }>(`${url}${path}/${file_id || ''}`, {}),
});

export default files;
