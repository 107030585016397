import {createSlice} from '@reduxjs/toolkit';
import {DialogData} from '../models/dialog-data';
import {ActionType} from '../models/action-type';
import {SendMailDialog} from '../components/dialog/send-mail-dialog';
import {MessageDialog} from '../components/dialog/message-dialog';

export type DialogState = {
  dialogs: DialogData[],
};

const initialState: DialogState = {
  dialogs: [],
};

const push: ActionType<DialogState, DialogData> = (state, action) => {
  state.dialogs.push(action.payload);
};
const pushSendMail: ActionType<DialogState, { message: string[], callback: () => void }> = (state, action) => {
  state.dialogs.push({
    title: '',
    element: SendMailDialog(action.payload.message, action.payload.callback),
  });
};
type MessageDialogData = {
  title: string,
  message: string[],
  buttons: {
    label: string,
    callback: () => void,
    color?: 'primary' | 'secondary' | 'tertiary' | 'quaternary';
  }[],
};
const pushMessage: ActionType<DialogState, MessageDialogData> = (state, action) => {
  state.dialogs.push({title: action.payload.title, element: MessageDialog(action.payload.message, action.payload.buttons)});
};
const pop: ActionType<DialogState> = (state) => {
  state.dialogs.splice(state.dialogs.length - 1, 1);
};
const popAll: ActionType<DialogState> = (state) => {
  state.dialogs = [];
};

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    push,
    pushSendMail,
    pushMessage,
    pop,
    popAll,
  },
});

export const dialogAction = dialogSlice.actions;
export const dialogReducer = dialogSlice.reducer;
