import con from '../../../connection';
import { ApiGetCompanyArticlesResponse } from './api-get-company-articles-response';
import { move } from './move';

const path = '/articles'

export const articles = (url: string) => (article_id?: string) => ({
  move: move(`${url}${path}/${article_id}`),
  get: <
    ResponseType extends ApiGetCompanyArticlesResponse
    >(param: {
    sort_by?: number,
    highlow?: number,
    limit?: number,
    page?: number,
  }) => con.get<ResponseType>(url + path, param),
})