import { push } from "@lagunovsky/redux-react-router";
import { useCallback, useMemo } from "react";
import { useAppDispatch } from '../../../../app/hooks';
import { persistor } from "../../../../app/store";
import { apiCompany, apiAdmin, ApiManager } from '../../../../managers/api-manager';
import { RoutingPath } from "../../../../routes/routing-path";
import { dialogAction } from "../../../../slices/dialog-slice";
import { loginCompanyUserInfoAction } from "../../../../slices/login-company-user-info-slicer";
import { loginIspUserInfoAction, LoginUserInfoSlicerState } from "../../../../slices/login-user-info-slicer";

type Props = {
  mode?: "admin" | "company";
}

export const useIsLogin = (props: Props) => {
  const dispatch = useAppDispatch();
  const isCompany = useMemo(() => props.mode === 'company', [props.mode]);
  const apiModule = useMemo(() => {
    if (isCompany) {
      return apiCompany.islogin();
    } else {
      return apiAdmin.islogin();
    }
  }, [isCompany]);

  const getCompanyInfo = useCallback(async(member_id: string) => {
    try {
      const memberData = await apiCompany.members(member_id).get();
      const iconData = await apiCompany.icon().get();
      dispatch(loginCompanyUserInfoAction.setCompanyUserDetail({
        companyUserInfo: memberData.body.data as any,
      }));
      dispatch(loginCompanyUserInfoAction.setCompanyUserIcon({
        companyUserIcon: {
          Filedata: iconData.body.data.Filedata,
        },
      }));      
    } catch(e) {
      ApiManager.errorFunc(e);
    }
  }, [dispatch]);

  const getAdminInfo = useCallback((user_id: string) => {
    apiAdmin.users(user_id).get()
    .then((res) => {
      const data = res.body.data as unknown as LoginUserInfoSlicerState['userInfo'];
      dispatch(loginIspUserInfoAction.setIspUserDetail({
        userInfo: data,
      }));
    }).catch((e) => ApiManager.errorFunc(e))
  }, [dispatch]);

  const getIsLogin = useCallback(() => {
    apiModule.get().then((res: any) => {
      if (res.header.status_code === 401) {
        dispatch(dialogAction.pushMessage({
          title: `エラー${res.header.status_code ? ` (CODE : ${res.header.status_code})` : ''}`,
          message: ['ログインしていません', 'ログイン画面に遷移します'],
          buttons: [
            {
              label: 'CLOSE',
              callback: () => {
                dispatch(dialogAction.pop());
                dispatch(push(RoutingPath.apiAdminLogin));
              },
            },
          ],
        }));
        return;
      }
      if (res.header.status_code === 200) {
        if (isCompany) {
          getCompanyInfo(res.body.data.user_id);
        } else {
          getAdminInfo(res.body.data.user_id);
        }
        return;
      } else {
        persistor.purge();
        dispatch(dialogAction.pushMessage({
          title: `エラー(CODE : 401)`,
          message: ['ログインしていません', 'ログイン画面に遷移します'],
          buttons: [
            {
              label: 'CLOSE',
              callback: () => {
                dispatch(dialogAction.pop());
                dispatch(push(RoutingPath.apiAdminLogin));
              },
            },
          ],
        }));
      }

      
    })
  }, [apiModule, getAdminInfo, isCompany, dispatch, getCompanyInfo]);
  return {
    getIsLogin,
  }
}