import con from '../../../../../../connection';

const path = '/download'

type AuthIdType = '1' | '2' | '101' | '102' | '103' | '104'

const download = (url: string) => () => ({
    get:<ApiGetAuthDlResponse>(authId: AuthIdType) => con.getFile(`${url}/${authId}${path}`, {}, false, 30000, true),
});

export default download;