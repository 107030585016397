import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../../app/store';
import { apiAdmin, ApiManager } from '../../managers/api-manager';
import { companyUserAction } from '../company-user-slice';

export type ApiIspCompanyUsersSliceState = {};

const initialState: ApiIspCompanyUsersSliceState = {};

export const apiIspCompanyUsersSlice = createSlice({
  name: 'api-isp-company-users',
  initialState,
  reducers: {},
});

const asyncActions = {
  register:
    (companyCode: string): AppThunk =>
    async (dispatch) => {
      try {
        const connectionId = ApiManager.connectStart('');
        const res = await apiAdmin
          .companies(companyCode)
          .members()
          .get();
          ApiManager.connectEnd(connectionId);
      } catch (e) {
        ApiManager.errorFunc(e);
      }
    },
  update:
    (data: {
      companyCode: string;
      userId: string;
      param: {
        organization_code: string;
        family_name: string;
        name: string;
        family_name_kana: string;
        name_kana: string;
        display_name: string;
        industry_id: number;
        occupation_id: number;
        role: number;
        is_information: boolean;
        is_log_admin: boolean;
        is_cadect1: boolean;
        is_cadect2: boolean;
        is_cadect3: boolean;
        is_cadect4: boolean;
        is_owner: boolean;
        is_retirement: boolean;
        authorities: string;
      };
      callback: {
        success: () => void,
        failed: () => void,
      }
    }): AppThunk =>
    async (dispatch) => {
      try {
        const connectionId = ApiManager.connectStart('');
        const res = await apiAdmin
          .companies(data.companyCode)
          .members(data.userId)
          .post(data.param as any);
          data.callback.success();
          ApiManager.connectEnd(connectionId);
      } catch (e) {
        ApiManager.errorFunc(e);
          data.callback.failed();
      }
    },
};

export const apiIspCompanyUsersAction = Object.assign(
  apiIspCompanyUsersSlice.actions,
  asyncActions,
);
export const apiIspCompanyUsersReducer = apiIspCompanyUsersSlice.reducer;
