import con from '../../../../connection';

const path = '/reuse';

const reuse = (url: string) => () => {
  return {
    post: () =>
      con.post<{
      header: {
        status: string;
        status_code: number;
        messages: string[];
      };
      body: {
        data: [];
      };
    }>( url + path, {})
  }
}

export default reuse;