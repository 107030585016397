import { createSlice } from '@reduxjs/toolkit';
import { ActionType } from '../models/action-type';
import { PortalData } from '../models/portal-data';

export type PortalState = {
  portals: PortalData[];
};

const initialState: PortalState = {
  portals: [],
};

const get: ActionType<PortalState> = (state) => {
  state.portals.push();
};

const pop: ActionType<PortalState> = (state) => {
  state.portals.splice(0, state.portals.length);
};

export const portalSlice = createSlice({
  name: 'portal',
  initialState,
  reducers: {
    get,
    pop,
  },
});

export const portalAction = portalSlice.actions;
export const portalReducer = portalSlice.reducer;
