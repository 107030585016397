import React, { useState } from 'react';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { Button } from '../../ui/button/button';
import { push } from '@lagunovsky/redux-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { dialogAction } from '../../../slices/dialog-slice';
import { useDidMount } from '../../../hooks/life-cycle';
import { QueryParamFormatter } from '../../../utilities/query-param-formatter';
import { apiAdmin, apiCompany, apiData, ApiManager } from '../../../managers/api-manager';
import { CompanyData } from '../../../models/company-data';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

export type UserAddCheckProps = {
  isCompanyUser: boolean;
  companyCode?: string;
} & PageComponentDefaultProps;

export const AdminUserAddCheck = (props: UserAddCheckProps) => {
  const { companyCode, isCompanyUser, apiManger } = props;
  const companyUser = useAppSelector((state) => state.companyUser.companyUserData);
  const ispUser = useAppSelector((state) => state.ispUser.userData);

  const dispatch = useAppDispatch();
// -------------------- state --------------------
  const [organizationName, setOrganizationName] = useState('');
  const [isOwner, setIsOwner] = useState(0);
  const [isKindUser, setIsKindUser] = useState(true);
  const [token, setToken] = useState('');
  const [memberId, setMemberId] = useState('');
  const [mail, setMail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [errorFlag, setErrorFlag] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  // -------------------- イベント --------------------
  /** キャンセル押下 */
  const handleClickReturn = () => {
    if (isCompanyUser) {
      // 企業ユーザーの場合
      if (apiManger.type === 'admin') {
        const path = RoutingPath.adminCompanyUserAdd
          .replace(':companyCode', companyCode!);
        const queryToken = QueryParamFormatter.queryCompose({ token: token });
        const queryIsOwner = QueryParamFormatter.queryCompose({ is_owner: isOwner });
        dispatch(push(`${path}?${queryToken}&${queryIsOwner}`));
      } else {
        const path = RoutingPath.companyUserAdd;
        const queryToken = QueryParamFormatter.queryCompose({ token: token });
        const queryIsOwner = QueryParamFormatter.queryCompose({ is_owner: isOwner });
        dispatch(push(`${path}?${queryToken}&${queryIsOwner}`));
      }
    } else {
      // M-LOOPユーザーの場合
      const path = RoutingPath.adminUserAdd;
      const query = QueryParamFormatter.queryCompose({ token: token });
      dispatch(push(`${path}?${query}`));
    }
  };
  /** M-LOOPユーザー登録処理 */
  const addIspUser = () => {
    apiAdmin.users().activate().post({
      token: ispUser.token,
      user_id: ispUser.userId,
      email: ispUser.mail,
      family_name: ispUser.familyName,
      name: ispUser.fastName,
      family_name_kana: ispUser.familyNameKana,
      name_kana: ispUser.fastNameKana,
      password: ispUser.password,
    }).then((res) => {
      dispatch(dialogAction.pushMessage({
        title: '完了',
        message: ['登録が完了しました。'],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogAction.pop());
            dispatch(push(RoutingPath.apiAdminLogin));
          },
        }],
      }));
    })
      .catch((e) => {
        ApiManager.errorFunc(e);
        setIsDisabled(false)
      });
  };
  /** 登録押下 */
  const handleClickAdd = () => {
    setIsDisabled(true)
    if (isCompanyUser) {
      apiData.users().activate().post({
        token,
        kind: isKindUser,
        member_id: memberId,
        email: mail,
        is_owner: Number(isOwner) ? true : false,
        family_name: companyUser.familyName,
        name: companyUser.fastName,
        family_name_kana: companyUser.familyNameKana,
        name_kana: companyUser.fastNameKana,
        password: companyUser.password,
      }).then((v) => {
        if (apiManger.type === 'admin') {
          const path = RoutingPath.adminCompanyUserAddResult
            .replace(':companyCode', companyCode!);
          const queryRole = QueryParamFormatter.queryCompose({ role: v.body.data.role });
          dispatch(push(`${path}?${queryRole}`));
        } else {
          const path = RoutingPath.companyUserAddResult;
          const queryRole = QueryParamFormatter.queryCompose({ role: v.body.data.role });
          dispatch(push(`${path}?${queryRole}`));
        }
      })
        .catch((e) => {
          ApiManager.errorFunc(e);
          setIsDisabled(false)
        });
    } else {
      addIspUser()
    }
  };
  // -------------------- effect --------------------
  /** トークンチェック */
  useDidMount(() => {
    const token = QueryParamFormatter.queryParse().token;
    const queryToken = QueryParamFormatter.queryCompose({ token: token });
    setToken(token);
    if (isCompanyUser) {
      const isOwner = QueryParamFormatter.queryParse().is_owner;
      const queryIsOwner = QueryParamFormatter.queryCompose({ is_owner: isOwner });
      setIsOwner(isOwner);
      if (!companyUser.member_id) {
        if (apiManger.type === 'admin') {
          const path = RoutingPath.adminCompanyUserAdd
            .replace(':companyCode', companyCode!);
          dispatch(push(`${path}?${queryToken}&${queryIsOwner}`));
          return;
        } else {
          const path = RoutingPath.companyUserAdd;
          dispatch(push(`${path}?${queryToken}&${queryIsOwner}`));
          return;
        }
      }
      apiData.users().auth().post({ token }).then((v) => {
        setCompanyName(v.body.data.company_name);
        setMail(v.body.data.email);
        setIsKindUser(v.body.data.kind);
        setMemberId(v.body.data.member_id);
        setOrganizationName(v.body.data.organization_name);
        setToken(v.body.data.token);
        setErrorFlag(true);
      })
        .catch((e) => {
          ApiManager.errorFunc(e)
          setErrorFlag(false);
          dispatch(push(RoutingPath.adminErrorToken));
        });
    } else {
      setErrorFlag(true);
      if (!ispUser.userId) {
        const path = RoutingPath.adminUserAdd;
        const query = QueryParamFormatter.queryCompose({ token: token });
        dispatch(push(`${path}?${query}`));
        return;
      }
    }
  });

  return (
    !errorFlag ?
      <></> :
      <div className="dialog_wrap direct_access check">
        <div className="dialog">
          <header>
            入力内容確認
          </header>
          <div className="dialog_body">
            {(isCompanyUser) ? (
              <div className="company_info_box">
                <div>企業名：{companyName}</div>
                <div>組織名：{organizationName}</div>
              </div>
            ) : (
              <></>
            )}
            <div className="edit_wrap">
              <div className="edit_box">
                <div className="item_wrap">
                  <div className="item_head">ユーザー名</div>
                  <div className="item_cnt">
                    {(isCompanyUser) ? (
                      <>
                        <label className="text_box">
                          {companyUser.familyName}&nbsp;{companyUser.fastName}
                        </label>
                      </>
                    ) : (
                      <>
                        <label className="text_box">
                          {ispUser.familyName}&nbsp;{ispUser.fastName}
                        </label></>
                    )}
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_head">ユーザー名（カナ）</div>
                  <div className="item_cnt">
                    {(isCompanyUser) ? (
                      <>{companyUser.familyNameKana}&nbsp;{companyUser.fastNameKana}</>
                    ) : (
                      <>{ispUser.familyNameKana}&nbsp;{ispUser.fastNameKana}</>
                    )}
                  </div>
                </div>
                {(!isCompanyUser) ? (
                  <div className="item_wrap">
                    <div className="item_head">メールアドレス</div>
                    <div className="item_cnt">
                      <label className="text_box">
                        {ispUser.mail}
                      </label>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="item_wrap">
                  <div className="item_head">パスワード</div>
                  <div className="item_cnt">
                    <label className="text_box">
                      ●●●●●●●●
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <footer>
              <Button
                size="large"
                color="tertiary"
                label={'戻る'}
                onClick={handleClickReturn}
              />
              <Button
                size="large"
                color="primary"
                label={'登録'}
                onClick={handleClickAdd}
                disabled={isDisabled}
              />
            </footer>
          </div>
        </div>
      </div>
  );
};
