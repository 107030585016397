import { OptionsType } from '../..';
import con from '../../../../connection';

const path = '/send';

const send = (url: string) => (request_id?: string) => {
  return {
    get: (param?: {
      article_name?: string,
      sort_by?: number,
      highlow?: number,
      limit?: number,
      offset?: number,
    }, options?: OptionsType) => request_id
      ? con.get(url + path + '/' + request_id, {}, options?.noError, options?.noNetworkError)
      : con.get(url + path, param || {}, options?.noError, options?.noNetworkError),
    delete: (options?: OptionsType) => con.delete(url + path + '/' + request_id || '', {}, options?.noError),
  };
};

export default send;
