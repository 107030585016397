import con from '../../../../connection';

const path = '/occupations';

export const occupations = (url: string) => () => ({
  get: con.get<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
    body: {
      data: {
        id: number,
        name: string,
        is_valid: boolean,
      }[],
    },
  }>(url + path, {}),
});
