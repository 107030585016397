import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useDidMount, useWillUnMount } from '../../../hooks/life-cycle';
import { apiAdmin, apiCompany } from '../../../managers/api-manager';
import { Checkbox } from '../../ui/checkbox/checkbox';
import { Input } from '../../ui/input/input';
import { Select } from '../../ui/select/select';
import './information-editor.scss';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { CheckAllValid, Validation } from '../../../managers/validation/validation';
import { ValidationFactory } from '../../../managers/validation/validation-factory';
import {
  InfoInitParams,
  InfoParams,
  informationAction,
  InformationDefaultData,
} from '../../../slices/information-slice';
import { LabeledForm } from '../../ui/input/labeled-form';
import { isEqual, cloneDeep } from 'lodash';
import { DatePicker } from '../../ui/date-picker/date-picker';
import { InfomationDetailEntity } from './type/infomation.type';
import { RefObject } from 'react';
import { DateFormatter } from '../../../utilities';
import { useEditorJs } from '../../../hooks/editor-js';
import { RichText } from '../../ui/rich-text/rich-text';
import { OutputData } from '@editorjs/editorjs';
import { createPlaneText } from '../../../utilities/create-plane-text';

type InformationEditorProps = {
  mode: 'add' | 'edit' | 'clone';
  isDisabled: boolean;
  checkValid: (e: boolean) => void;
  callback?: (info: InfoParams) => void;
  initParam?: InfomationDetailEntity;
  isInit?: boolean;
} & PageComponentDefaultProps;

type CategoryInfo = {
  id: number;
  name: string;
  sort_order: number;
  is_valid: number;
}

type SelectInfo = {
  key: string;
  label: string;
}

// バリデーション用意
const validations: { [key: string]: Validation } = {
  requireTitle: ValidationFactory('require'),
  requireBody: ValidationFactory('default'),
  requireUrl: ValidationFactory('default'),
  requireCategory: ValidationFactory('require'),
  requireSenderName: ValidationFactory('require'),
  requireStartDate: ValidationFactory('require'),
  requireEndDate: ValidationFactory('require'),
  checkEndDate: ValidationFactory('require'),

  length128: ValidationFactory('length128'),
  length2048: ValidationFactory('length2048'),
  // length1024: ValidationFactory('require'),
};

export const InformationEditor = (props: InformationEditorProps) => {
  const { mode, apiManger, isDisabled, checkValid, callback, initParam, isInit } = props;
  const dispatch = useAppDispatch();
  const { companyUserInfo, userInfo, infoData } = useAppSelector((state) => ({
    companyUserInfo: state.loginCompanyUserInfo.companyUserInfo,
    userInfo: state.loginIspUserInfo.userInfo,
    infoData: state.information.informationData,
  }), isEqual);


  /* Hooks */
  const [title, setTitle] = useState('');
  const [body, setBody] = useState<OutputData | undefined>(undefined);
  const [initBody, setInitBody] = useState(false);
  const [categoryList, setCategoryList] = useState<CategoryInfo[]>([]);
  const [category, setCategory] = useState('');
  const [levelIsCheck, setLevelIsCheck] = useState(false);
  const [url, setUrl] = useState('');
  const [urlIsCheck, setUrlIsCheck] = useState(false);
  const [senderName, setSenderName] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [validFlag, setValidFlag] = useState(false);
  const [creatorName, setCreatorName] = useState('');

  // バリデーションのトータルチェック
  const [checkValidation, setCheckValidation] = useState(CheckAllValid(validations));
  const [validInfo, setValidInfo] = useState<Partial<InfoInitParams> | null>(null);

  /* Ref */
  const startDpRef = useRef<any>(null);
  const startRef = useRef<HTMLInputElement>(null);
  const endRef = useRef<any>(null);
  const endDpRef = useRef<HTMLButtonElement>(null);
  const titleRef = useRef<HTMLInputElement>(null);
  const senderNameRef = useRef<HTMLInputElement>(null);
  const urlRef = useRef<HTMLInputElement>(null);
  const bodyRef = useRef<HTMLTextAreaElement>(null);
  const categoryRef = useRef<any>(null);
  const ancestorRef = useRef<HTMLDivElement>(null);
  const mounted = useRef(false);

  const baseApi = useMemo(() => apiManger.type === 'company' ? apiCompany : apiAdmin, [apiManger]);

  const validRefCollection = useMemo<{ [key in keyof InfoInitParams]: RefObject<any> }>(() => ({
    title: titleRef,
    body: bodyRef,
    url: urlRef,
    senderName: senderNameRef,
    notificationStartDate: startDpRef,
    notificationEndDate: endDpRef,
    category: categoryRef,
  }), [startDpRef, endDpRef, titleRef, bodyRef, urlRef, senderNameRef]);

  const confirmValid = useMemo(() => {
    if (!title || !category || !senderName || !startDate || !endDate) return false;
    const endDateStr = DateFormatter.date2str(endDate, 'YYYYMMDD', '-');
    const startDateStr = DateFormatter.date2str(startDate, 'YYYYMMDD', '-');
    if (urlIsCheck && !url) return false;
    if (!urlIsCheck && !(body)) return false;
    if (!urlIsCheck && body && !createPlaneText(body)) return false;
    if (!urlIsCheck && body) {
      if (!ValidationFactory('length1024').test(createPlaneText(body))) return false;
    }
    // if (!urlIsCheck && !body) return false;
    if (!ValidationFactory('length128').test(title)
      || (urlIsCheck && !ValidationFactory('length2048').test(url))
      || (!ValidationFactory('length128').test(senderName))) return false;
    if (!(Number(startDateStr.replace(/-/g, '')) <= Number(endDateStr.replace(/-/g, '')))) return false;
    return true;
  }, [title, body, category, startDate, endDate, senderName, url, urlIsCheck]);

  // - タイトル -
  const changeTitle = useCallback((v: string) => {
    setTitle(v);
  }, [title]);
  // - カテゴリー -
  const changeCategory = useCallback((v: string | React.ChangeEvent<HTMLSelectElement>) => {
    setCategory(v as string);
  }, []);
  const onClickUrlCheckBox = useCallback((v: boolean) => {
    setUrlIsCheck(v);
    setTimeout(() => {
      if (!urlRef.current) return;
      urlRef.current.focus();
      urlRef.current.blur();
    }, 0);
  }, [urlRef]);

  // - URL -
  const changeUrl = useCallback((v: string) => {
    setUrl(v);
  }, [url]);
  // - 開通開始日 -
  const changeStartDate = useCallback((v: Date | null) => {
    setStartDate(v);
    if (startRef.current) {
      validations['checkEndDate'] = new Validation({
        test: (v2: string) => {
          return v2 === '' || Number(startRef.current!.value.replace(/-/g, '')) <= Number(v2.replace(/-/g, ''));
        },
        errorMessages: ['通知開始日より過去日の設定はできません'],
      });
    }
    ;
    window.setTimeout(() => {
      if (endRef.current) {
        if (!v) {
          startRef?.current?.focus();
          startRef?.current?.blur();
        }
        endRef.current.focus();
        endRef.current.blur();
      }
    }, 0);
  }, [startRef, endRef]);
  // - 通知終了日 -
  const changeEndDate = useCallback((v: Date | null) => {
    setEndDate(v);
    window.setTimeout(() => {
      if (startRef.current) {
        if (!v) {
          endRef?.current?.focus();
          endRef?.current?.blur();
        }
        startRef.current.focus();
        startRef.current.blur();
      }
    }, 0);
  }, [startRef, endRef]);
  // - 差出人 -
  const changeSenderName = useCallback((v: string) => {
    setSenderName(v);
  }, [senderName]);
  // - redux -
  // const onBlurFunc = useCallback(() => {
  //   dispatch(informationAction.setInformationData({
  //     title: title,
  //     body: '',
  //     level: levelIsCheck ? 0 : 1,
  //     category: category ? Number(category) : -1,
  //     isUrl: urlIsCheck ? 1 : 0,
  //     url: url,
  //     notificationStartDate: startDate ? startDate : null,
  //     notificationEndDate: endDate ? endDate : null,
  //     senderName: senderName,
  //   }));
  // }, [title, categoryList, category, levelIsCheck, url, urlIsCheck, senderName, startDate, endDate]);

  // - 日付のファーマット -
  const formatDate = (v: Date, delimiter: string) => (v.getFullYear() + delimiter + (v.getMonth() + 1) + delimiter + v.getDate());
  const [currentTime, setCurrentTime] = useState(new Date());
  const creationDate: string = formatDate(currentTime, '/');

  /* - カテゴリーリスト - */
  const categoryViewList = useMemo<SelectInfo[]>(() => {
    return categoryList.map((v) => ({ key: v.id.toString(), label: v.name }));
  }, [categoryList]);

  // - カテゴリーの取得 -
  useDidMount(() => {
    validations.requireTitle = ValidationFactory('require');
    validations.requireBody = ValidationFactory('default');
    validations.requireUrl = ValidationFactory('default');
    validations.requireCategory = ValidationFactory('require');
    validations.requireSenderName = ValidationFactory('require');
    validations.requireStartDate = ValidationFactory('require');
    validations.requireEndDate = ValidationFactory('require');
    validations.checkEndDate = ValidationFactory('require');
    validations.length128 = ValidationFactory('length128');
    validations.length2048 = ValidationFactory('length2048');

    baseApi.informations().categories().get()
      .then((data) => {
        setCategoryList(data.body.data);
        setValidFlag(true);
      });
  });

  useEffect(() => {
    if (!validInfo) return;
    if (!mounted.current) {
      mounted.current = true;
      return;
    }
    const keys = Object.keys(validInfo) as (keyof InfoInitParams)[];
    keys.forEach((v) => {
      if (v === 'notificationEndDate') {
        if (startRef.current && endRef.current) {
          validations['checkEndDate'] = new Validation({
            test: (v: string) =>
              v === '' || Number(startRef.current!.value.replace(/-/g, '')) <= Number(v.replace(/-/g, '')),
            errorMessages: ['通知開始日より過去日の設定はできません'],
          });
        }
        ;
        window.setTimeout(() => {
          endRef?.current?.focus?.();
          endRef?.current?.blur?.();
        }, 0);
      } else if (v === 'notificationStartDate') {
        window.setTimeout(() => {
          startRef?.current?.focus?.();
          startRef?.current?.blur?.();
        }, 0);
      } else {
        window.setTimeout(() => {
          validRefCollection?.[v].current?.focus?.();
          validRefCollection?.[v].current?.blur?.();
        }, 0);
      }
    });
    window.setTimeout(() => {
      setCheckValidation(CheckAllValid(validations));
      // checkValid(!CheckAllValid(validations))
    }, 0);
  }, [validInfo, validRefCollection]);

  useEffect(() => {
    const _info: Partial<InfoInitParams> = {};
    // if (!categoryList.length || validInfo) return;
    if (initParam) {
      setTitle(initParam.title);
      setCategory(initParam.category > 0 ? initParam.category.toString() : '');
      setLevelIsCheck(initParam.level === 2);
      setUrlIsCheck(!!initParam.is_url);
      setUrl(initParam.url);
      setCurrentTime(new Date(initParam.created_at));
      setStartDate(initParam.notification_start_date ? new Date(initParam.notification_start_date) : null);
      setEndDate(initParam.notification_end_date ? new Date(initParam.notification_end_date) : null);
      setSenderName(initParam.sender_name);
      const ispName = (mode === 'edit' ? initParam.created_display_name || `${userInfo.family_name}${userInfo.name}` : `${userInfo.family_name}${userInfo.name}`);
      const companyName = (mode === 'edit' ? initParam.created_display_name || companyUserInfo.display_name : companyUserInfo.display_name);
      setCreatorName(apiManger.type === 'company' ? companyName : ispName);
      if (initParam.body) {
        if (typeof initParam.body === 'string') {
          setBody(cloneDeep(JSON.parse(initParam.body)));
        } else {
          setBody(cloneDeep(initParam.body));
        }
        setInitBody(false);
      } else {
        setBody(undefined);
        setInitBody(true);
      }
      if (initParam.notification_start_date) _info.notificationStartDate = true;
      if (initParam.notification_end_date) _info.notificationEndDate = true;
      if (initParam.title) _info.title = true;
      if (initParam.sender_name) _info.senderName = true;
      if (initParam.body) _info.body = true;
      if (initParam.url) _info.url = true;
      if (initParam.category) _info.category = true;
    } else {
      setTitle(infoData.title);
      setCategory(infoData.category > 0 ? infoData.category.toString() : '');
      setLevelIsCheck(infoData.level === 1);
      setUrlIsCheck(!!infoData.isUrl);
      setUrl(infoData.url);
      setStartDate(infoData.notificationStartDate ? infoData.notificationStartDate : null);
      setEndDate(infoData.notificationEndDate ? infoData.notificationEndDate : null);
      setSenderName(infoData.senderName ?? (apiManger.type === 'company' ? companyUserInfo.display_name : `${userInfo.family_name}${userInfo.name}`));
      setCreatorName(apiManger.type === 'company' ? companyUserInfo.display_name : `${userInfo.family_name}${userInfo.name}`);
      setBody(infoData.body as OutputData);
      setInitBody(!infoData.body);
      if (infoData.notificationStartDate) _info.notificationStartDate = true;
      if (infoData.notificationEndDate) _info.notificationEndDate = true;
      if (infoData.title) _info.title = true;
      if (infoData.senderName) _info.senderName = true;
      if (infoData.body) _info.body = true;
      if (infoData.url) _info.url = true;
      if (infoData.category > 0) _info.category = true;
    }
    if (Object.keys(_info).length) {
      setValidInfo(cloneDeep(_info));
    }
  }, [initParam, categoryList]);

  // useEffect(() => {
  //   if (startDpRef.current) {
  //     startDpRef.current.handleFocus();
  //     startDpRef.current.handleCalendarClickOutside();
  //     console.log(startDpRef.current)
  //   }
  // }, [startDpRef]);


  // - 「通知開始日」「通知終了日」バリデーション-
  useEffect(() => {
    if (!startRef.current || !endRef.current) return;
    validations['checkEndDate'] = new Validation({
      test: (v: string) => {
        return v === '' || Number(startRef.current!.value.replace(/-/g, '')) <= Number(v.replace(/-/g, ''));
      },
      errorMessages: ['通知開始日より過去日の設定はできません'],
    });
  }, [validFlag]);

  // TODO: Editor.js実装後、バリデーション追加
  useEffect(() => {
    if (urlIsCheck) {
      if (mode === 'add' && !infoData.url) {
        validations['requireUrl'] = ValidationFactory('require');
      } else {
        if (initParam) {
          const require = !!initParam.is_url ? 'requireEdit' : 'require';
          validations['requireUrl'] = ValidationFactory(require);
        } else {
          const require = !!infoData.isUrl ? 'requireEdit' : 'require';
          validations['requireUrl'] = ValidationFactory(require);
        }
        // validations['requireUrl'] = ValidationFactory('requireEdit');
      }
      // validations['requireBody'] = ValidationFactory('default');
    } else {
      // if (mode === 'add' && !infoData.body) {
      //   validations['requireBody'] = ValidationFactory('require');
      // } else {
      //   validations['requireBody'] = ValidationFactory('require');
      // }
      validations['requireUrl'] = ValidationFactory('default');
    }
  }, [urlIsCheck]);

  const onChangeBody = useCallback((e: OutputData) => {
    setBody(e);
  }, []);

  // - バリデーションチェック-
  useEffect(() => {
    window.setTimeout(() => {
      if (!confirmValid) {
        setCheckValidation(true);
        checkValid(false);
        return;
      }
      setCheckValidation(CheckAllValid(validations));
    }, 0);
  }, [startDate, endDate, title, category, senderName, url, urlIsCheck, body, confirmValid]);

  // - 確認ボタンの「disabled」-
  useEffect(() => {
    if (!confirmValid) {
      checkValid(false);
      return;
    }
    if (checkValidation) {
      checkValid(false);
    } else {
      checkValid(true);
    }
  }, [checkValidation]);

  // - 親コンポーネントにデータを渡す -
  useEffect(() => {
    if (callback) {
      callback({
          title: title,
          body: body,
          level: levelIsCheck ? 1 : 0,
          category: category ? Number(category) : -1,
          isUrl: urlIsCheck ? 1 : 0,
          url: url,
          notificationStartDate: startDate ? startDate : null,
          notificationEndDate: endDate ? endDate : null,
          senderName: senderName,
        },
      );
    }
  }, [title, body, levelIsCheck, category, urlIsCheck, url, startDate, endDate, checkValidation, senderName]);

  return (
    <div
      className="information_editor"
      ref={ancestorRef}
    >
      <div className="information_editor__inner">
        <div className="information_editor__item">
          <div className="information_editor__item__head">タイトル<span className="required">必須</span></div>
          <div className="information_editor__item__cnt">
            <Input
              value={title}
              onChange={(e) => changeTitle(e.target.value)}
              ref={titleRef}
              // onBlur={onBlurFunc}
              validations={[
                validations.requireTitle,
                validations.length128,
              ]}
              edgeEle={mode === 'edit' ? (ancestorRef.current !== null ? ancestorRef.current : undefined) : window}
            />
          </div>
        </div>
        <hr />
        <div className="information_editor__item">
          <div className="information_editor__item__head">内容{!urlIsCheck &&
            <span className="required">必須</span>}</div>
          <div className="information_editor__item__cnt cnt_content">
            <RichText
              callback={onChangeBody}
              initData={body}
              isAdd={initBody}
              isReadOnly={urlIsCheck}
              validation={[
                ValidationFactory(urlIsCheck ? 'default' : 'require'),
                ValidationFactory('length1024'),
              ]}
              isEdit
              edgeEle={mode === 'edit' ? (ancestorRef.current !== null ? ancestorRef.current : undefined) : window}
              // maxOption={titleRef.current ? { towEle: titleRef.current, offset: 24 } : undefined}
            />
          </div>
        </div>
        <hr />
        <div className="information_editor__item">
          <div className="information_editor__item__head">カテゴリ<span className="required">必須</span></div>
          <div className="information_editor__item__cnt">
            <Select
              value={category}
              list={categoryViewList}
              _ref={categoryRef}
              onChange={changeCategory}
              placeholder="選択してください"
              className="alt_select"
              validations={[
                validations.requireCategory,
              ]}
            />
          </div>
        </div>
        <hr />
        <div className="information_editor__item">
          <div className="information_editor__item__head">通知レベル</div>
          <div className="information_editor__item__cnt">
            <Checkbox
              label="重要"
              onClick={() => setLevelIsCheck(!levelIsCheck)}
              id="level"
              checked={levelIsCheck}
            />
          </div>
        </div>
        <hr />
        <div className="information_editor__item">
          <div className="information_editor__item__head">通知URL{urlIsCheck &&
            <span className="required">必須</span>}</div>
          <div className="information_editor__item__cnt cnt_notification_url">
            <Checkbox
              label={`URLリンク指定`}
              onClick={() => onClickUrlCheckBox(!urlIsCheck)}
              extraLabel={{
                label: ' ※URLを設定する場合、内容にはテキスト入力及び配信は行なえません。',
                color: '#F90000',
              }}
              id="notificationUrl"
              checked={urlIsCheck}
            />
            <Input
              value={url}
              asyncDisabled
              onChange={(e) => changeUrl(e.target.value)}
              ref={urlRef}
              // onBlur={onBlurFunc}
              disabled={!urlIsCheck}
              validations={[
                validations.length2048,
                validations.requireUrl,
              ]}
            />
          </div>
        </div>
        <hr />
        <div className="information_editor__item">
          <div className="information_editor__item__head">通知開始日 / 通知終了日<span className="required">必須</span>
          </div>
          <div className="information_editor__item__cnt">
            <div className="period_form">
              <LabeledForm
                label=""
                formEle={
                  <Input
                    type="date"
                    value={startDate ? DateFormatter.date2str(startDate, 'YYYYMMDD', '-') : ''}
                    onClickClearDate={() => {
                      changeStartDate(null);
                    }}
                    overErrorIgnore
                    onChange={(e) => changeStartDate(e.target.value ? new Date(e.target.value) : null)}
                    validations={[validations.requireStartDate]}
                    ref={startRef}
                  />
                  //   <DatePicker
                  //     handleChange={changeStartDate}
                  //     startDate={startDate}
                  //     validations={[validations.requireStartDate]}
                  //     _ref={startRef}
                  //     dpRef={startDpRef}
                  //   />
                }
              />
              <div className="period_form__tilde" />
              <LabeledForm
                label=""
                formEle={
                  <Input
                    type="date"
                    value={endDate ? DateFormatter.date2str(endDate, 'YYYYMMDD', '-') : ''}
                    onChange={(e) => changeEndDate(e.target.value ? new Date(e.target.value) : null)}
                    overErrorIgnore
                    onClickClearDate={() => {
                      changeEndDate(null);
                    }}
                    validations={[
                      validations.requireEndDate,
                      validations.checkEndDate,
                    ]}
                    ref={endRef}
                    edgeEle={ancestorRef.current !== null ? ancestorRef.current : undefined}
                  />
                  // <DatePicker
                  //   handleChange={changeEndDate}
                  //   startDate={endDate}
                  //   validations={[
                  //     validations.requireEndDate,
                  //     validations.checkEndDate,
                  //   ]}
                  //   ignoreValidationIndex={[1]}
                  //   dpRef={endDpRef}
                  //   _ref={endRef}
                  // />

                }
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="information_editor__item">
          <div className="information_editor__item__head">作成日 / 作成者</div>
          <div className="information_editor__item__cnt">
            <span>{`${creationDate} ${creatorName}`}</span>
          </div>
        </div>
        <hr />
        <div className="information_editor__item">
          <div className="information_editor__item__head">差出人<span className="required">必須</span></div>
          <div className="information_editor__item__cnt">
            <Input
              value={senderName}
              onChange={(e) => changeSenderName(e.target.value)}
              ref={senderNameRef}
              // onBlur={onBlurFunc}
              validations={[
                validations.requireSenderName,
                validations.length128,
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
