import React, { useCallback, useEffect, useRef, useState } from 'react';
import { push } from '@lagunovsky/redux-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import { useDidMount } from '../../../hooks/life-cycle';
import { apiCompany, ApiManager, apiAdmin } from '../../../managers/api-manager';
import { QueryParamFormatter } from '../../../utilities/query-param-formatter';
import { CheckAllValid, Validation } from '../../../managers/validation/validation';
import { ValidationFactory } from '../../../managers/validation/validation-factory';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { useAppDispatch } from '../../../app/hooks';

const validations: { [key: string]: Validation } = {
  requirePassword: ValidationFactory('require'),
  requirePasswordConfirm: ValidationFactory('require'),
  checkPasswordConfirm: ValidationFactory('default'),
  password: ValidationFactory('password'),
  confirmPassword: ValidationFactory('password'),
};

const PasswordReset = (props: PageComponentDefaultProps) => {
  const { apiManger } = props;
  const dispatch = useAppDispatch();
  const [userId, setUserId] = useState('');
  const [token, setToken] = useState('');
  const [errorFlag, setErrorFlag] = useState(false);
  // パスワード
  const [password, setPassword] = useState('');
  const handleChangePassword = useCallback((v: string) => {
    setPassword(v.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]*$/, ''));
  }, []);
  // 確認用パスワード
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const handleChangePasswordConfirm = useCallback((v: string) => {
    setPasswordConfirm(v.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]*$/, ''));
  }, []);

  // バリデーションのトータルチェック
  // const isDisabled = CheckAllValid(validations)
  const [isDisabled, setIsDisabled] = useState(CheckAllValid(validations));
  const [validFlag, setValidFlag] = useState(false);
  const passwordRef = useRef<HTMLInputElement>(null);
  const passwordConfirmRef = useRef<HTMLInputElement>(null);

  /** パスワード再発行 */
  const handleClickReset = useCallback(
    () => {
      const api = apiManger.type === 'company'
       ? apiCompany
       : apiAdmin;
      const path = apiManger.type === 'company'
       ? RoutingPath.companyPasswordResetResult
       : RoutingPath.adminPasswordResetResult;
       
      setIsDisabled(true)
      // パスワード再発行
      api.password().set().post({
        token,
        user_id: userId,
        password,
      }).then(() => {
        // リザルトへ遷移
        dispatch(push(path));
      }).catch((e) => {
        ApiManager.errorFunc(e);
        setIsDisabled(false)
      });
    },
    [password, userId, token],
  );

  useDidMount(() => {
    // トークンの取得
    const queryToken = QueryParamFormatter.queryParse().token;
    setToken(queryToken);
    // トークン認証
    apiCompany.password().auth().post({ token: queryToken })
      .then((v) => {
        setUserId(v.body.data.user_id);
        setErrorFlag(true);
      }).catch(() => {
      setErrorFlag(false);
      dispatch(push(RoutingPath.companyErrorToken));
    });
  });

  /**　パスワードのバリデーション監視 */
  const checkValidPasswordInput = useCallback(() => {
    if (!passwordRef.current ||
    !passwordConfirmRef.current ||
      passwordConfirmRef.current.value.length <= 0) {
      return;
    }
    passwordConfirmRef.current.focus();
    passwordConfirmRef.current.blur();
    passwordRef.current.focus();
  }, [password]);
  useEffect(() => {
    if (validFlag) {
      checkValidPasswordInput();
    }
  }, [password]);
  // トータルバリデーションチェック
  useEffect(() => {
    setIsDisabled(CheckAllValid(validations));
  }, [
    password,
    passwordConfirm,
    validFlag,
  ]);

  /** バリデーション更新 */
  useDidMount(() => {
    validations['password'] = ValidationFactory('password');
    validations['confirmPassword'] = ValidationFactory('password');
    validations['requirePassword'] = ValidationFactory('require');
    validations['requirePasswordConfirm'] = ValidationFactory('require');
    validations['checkPasswordConfirm'] = new Validation({
      test: (v: string) => v === passwordRef.current?.value,
      errorMessages: ['パスワードが一致しません'],
    });
    setValidFlag(true);
  });

  return (
    !errorFlag ?
      <></> :
      <>
        <div className="dialog_wrap direct_access password">
          <div className="dialog">
            <header>
              パスワード再発行
              <div className="text_box">
                ご希望のパスワードを入力してください
              </div>
            </header>
            <div className="dialog_body">
              <div className="edit_wrap">
                <div className="item_wrap">
                  <div className="item_head">
                    新しいパスワード
                    <span
                      className="required"
                      style={{ marginLeft: '5px' }}
                    >必須</span>
                  </div>
                  <div className="item_cnt">
                    <Input
                      type="password"
                      value={password}
                      onChange={(e) => handleChangePassword(e.target.value)}
                      validations={[
                        validations.requirePassword,
                        validations.password,
                      ]}
                      ref={passwordRef}
                    />
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_head">
                    新しいパスワード（確認用）
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt">
                    <Input
                      type="password"
                      value={passwordConfirm}
                      onChange={(e) => handleChangePasswordConfirm(e.target.value)}
                      validations={[
                        validations.requirePasswordConfirm,
                        validations.confirmPassword,
                        validations.checkPasswordConfirm,
                      ]}
                      ref={passwordConfirmRef}
                    />
                  </div>
                </div>
              </div>
              <footer>
                <Button
                  size="large"
                  label="パスワード再設定"
                  onClick={handleClickReset}
                  disabled={isDisabled}
                />
              </footer>
            </div>
          </div>
        </div>
      </>
  );
};

export default PasswordReset;
