import { ApiGetIspInformationsCategoriesResponse } from './api-get-isp-informations-categories.response';
import con from '../../../../connection';

const path = '/categories';

const categories = (url: string) => () => ({
  get: <
   ResponseType extends ApiGetIspInformationsCategoriesResponse
  >(param?: {
    is_valid?: number;
  }) => con.get<ResponseType>(url + path, param || {}),
});

export default categories;