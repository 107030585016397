import { OptionsType } from '../../';
import con from '../../../../connection';

const path = '/files';

const files = (url: string) => (fileId?: string) => ({
  post: (
    param: {
      /** ファイル */
      Filedata: File,
      /**
       * アップロード種別
       *
       * 0. 連絡ファイル
       * 1. コメントファイル
       */
      kind: number,
    },
    options?: OptionsType,
  ) => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
    body: {
      data: {
        /** ファイル ID */
        file_id: string,
        /** ファイル名 */
        name: string,
        /** 拡張子 */
        extension: string,
        /** ファイルサイズ */
        size: number,
        /** 画像フラグ */
        is_image: number,
        /** ダウンロード url */
        download_url: string,
        /**
         * 画像取得 URL
         *
         * 画像フラグが０の場合は、この値はNULLとなる
         * 等倍表示用
         */
        image_url: string,
        /**
         * サムネイル画像取得 URL
         *
         * 画像フラグが０の場合は、この値はNULLとなる
         * ※縮小表示用
         */
        thumb_url: string,
        /** アップロード日時 */
        created_at: string,
      },
    },
  }>(url + path, param, options?.noError, options?.timeoutMS),
  delete: <TResponse> (
    param: {
      /** { fileids[0]: piyo, fileids[1]: hoge ... } */
      fileids?: any,
    },
    option?: OptionsType,
  ) => con.delete<TResponse>(url + path, param, option?.noError),
  get: <TResponse> (
    param: {
      /**
       * 0: 連絡
       * 1: コメント
       */
      kind: number,
    },
    options?: OptionsType
  ) => con.getFile<TResponse>(url + path + '/' + fileId, param, options?.noError)
});

export default files;
