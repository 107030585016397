import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiAdmin, ApiManager } from '../../managers/api-manager';
import { ispUserAction } from '../isp-user-slice';
import { AppThunk } from '../../app/store';

export type IspUserSliceState = {};

const initialState: IspUserSliceState = {};

export const apiIspUsersSlice = createSlice({
  name: 'api-isp-users',
  initialState,
  reducers: {
    update: (
      state,
      action: PayloadAction<{
        userId: string;
        param: {
          family_name: string;
          name: string;
          family_name_kana: string;
          name_kana: string;
          email: string;
          is_mth_owner: boolean;
          is_retirement: boolean;
          pass: string;
        };
        callback: {
          success: () => void;
          failed: () => void;
        };
      }>,
    ) => {
      apiAdmin
        .users(action.payload.userId)
        .post(action.payload.param)
        .then((res) => {
          action.payload.callback.success();
        })
        .catch((e) => {
          ApiManager.errorFunc(e);
          action.payload.callback.failed();
        });
    },
  },
});

export const apiIspUsersAction = apiIspUsersSlice.actions;
export const apiIspUsersReducer = apiIspUsersSlice.reducer;
