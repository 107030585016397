import { LogDecorator } from '@tenryu/log-decorator';
import { RequestParameter } from './connection';

class LogClass {
  async onResponse(method: string, url: string, param: RequestParameter, response: Response) {
    const res = response.clone();
    const success = res.ok;
    const urlParser = url.indexOf('.') === 0 ? { pathname : url } : new URL(url);
    const headers = Object.fromEntries(Array.from(res.headers.entries()));
    // - log start -
    if (success) {
      LogDecorator.groupCollapsed(`[<green>${res.status}</green>] ${method} <gray>${urlParser.pathname}</gray>`);
    } else {
      LogDecorator.groupCollapsed(`[<red>${res.status}</red>] ${method} <gray>${urlParser.pathname}</gray>`);
    }
    // -- Request --
    console.group('Request');
    console.log('url   : ', url);
    console.log('param : ', param);
    console.groupEnd();
    // -- Response --
    console.group('Response');
    console.log('headers : ', headers);
    if (headers.hasOwnProperty('content-type') && headers['content-type'] === 'application/json') {
      console.log('body    : ', await res.json());
    } else {
      console.log('body    : ', await res.text());
    }
    console.groupEnd();
    LogDecorator.groupEnd();
  }
}

const Log = new LogClass();

export default Log;
