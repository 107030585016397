import con from '../../../connection';

const path = '/icon';

const icon = (url: string) => () => ({
  get: () => con.get<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
    body: {
      data: { Filedata: string },
    },
  }>(url + path, {}),
  post: (param: {
    Filedata: string,
  }) => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
  }>(url + path, param),
  delete: () => con.delete<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
  }>(url + path, {}),
});

export default icon;
