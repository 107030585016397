import con from '../../../../connection';

const path = '/files';

const files = (url: string) => (token: string) => ({
  // ファイルダウンロード
  get: (param?: { debug?: number }, noError?: boolean, timeoutMS?: number) => con.getFile<{
    header: {
      status: string,
      /** 
       * - 401:認証エラー
       * - 500:Internal Server Error
       * - 5001:DATA API連携エラー
       * - **5001については、DATA API側でエラー検出時にセットされる**
      */
      status_code: number,
      /**
       * - DATA APIにてエラー発生時のレスポンスステータスコードをセット
       * - エラー報告実施時にはこの値をAPIにセットする
       * - ブランクの場合あり
       * - DATA APIエラー以外の場合は、この変数は存在しない
       */
      error_code: string,
      messages: { message: string },
    },
  }>(`${url}/${token}${path}`, param || {}, noError, timeoutMS),
  // ファイルアップロード
  post: (param: {
    Filedata: File,
    debug?: number,
  },
  noError?: boolean,
  timeoutMS?: number) => con.post<{
    header: {
      status: string,
      /** 
       * - 401:認証エラー
       * - 500:Internal Server Error
       * - 5001:DATA API連携エラー
       * - **5001については、DATA API側でエラー検出時にセットされる**
      */
      status_code: number,
      /**
       * - DATA APIにてエラー発生時のレスポンスステータスコードをセット
       * - エラー報告実施時にはこの値をAPIにセットする
       * - ブランクの場合あり
       * - DATA APIエラー以外の場合は、この変数は存在しない
       */
      error_code: string,
      messages: { message: string },
    },
    body: {
      data: { file_id: string },
    }
  }>(`${url}/${token}${path}`, param, noError, timeoutMS),
});

export default files;
