import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Header } from '../../ui/header/header';
import { SideBar } from '../../ui/sidebar/sidebar';
import { Button } from '../../ui/button/button';
import { Input } from '../../ui/input/input';
import { push } from '@lagunovsky/redux-react-router';
import { BreadcrumbList } from '../../ui/breadcrumb-list/breadcrumb-list';
import { breadcrumbParts } from '../../../models/breadcrumb-parts';
import { RoutingPath } from '../../../routes/routing-path';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { SortListType, Table } from '../../table/table';
import { DateFormatter } from '../../../utilities';
import { Pager } from '../../ui/pager/pager';
import { apiAdmin } from '../../../managers/api-manager';
import { AdminUserData, AdminUserBody } from '../../../models/isp-user-data';
import { QueryParamFormatter } from '../../../utilities/query-param-formatter';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { IspLogOperation } from '../../../models/logs/isp-log-operation';
import { ApiGetAdminUsersResponse } from '../../../api/api/admin-web/users/api-get-isp-users.response';
const breadcrumbList = [
  breadcrumbParts.isp.home,
  breadcrumbParts.isp.ispUserList,
];

export const AdminUserList = (props: PageComponentDefaultProps) => {
  const { apiManger } = props;
  const [userList, setUserList] = useState<AdminUserData[]>([]);
  const [isCheckRetire, setIsCheckRetire] = useState(false);
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state) => state.loginIspUserInfo);

  const [ispUserBody, setIspUserBody] = useState<AdminUserBody>({
    current_page: 0,
    data: null,
    first_page_url: '',
    from: 0,
    last_page: 0,
    last_page_url: '',
    links: null,
    next_page_url: '',
    path: '',
    per_page: 0,
    prev_page_url: '',
    to: 0,
    total: 0,
  });

  //　表示データの全件数
  const totalData = ispUserBody.total;
  // 一度に表示するデータの件数
  const perData = ispUserBody.per_page;
  const [currentPage, setCurrentPage] = useState(ispUserBody.current_page); // 現在のページ
  const [queryFlag, setQueryFlag] = useState(true);
  // 表示開始のデータ番号
  const totalPage: number = useMemo(() => {
    const _totalPage = totalData % perData ?
      Math.floor(totalData / perData) : // 合計のページ数
      Math.floor(totalData / perData) - 1; // 合計のページ数(件数が丁度ページいっぱいで収まる時)
    return _totalPage;
  }, [totalData, perData]);

  const sortKey = {
    name: 1,
    createdAt: 2,
    loginAt: 3,
  };

  const highlowKey = {
    high: 0,
    low: 1,
  };

  const [sortBy, setSortBy] = useState(sortKey.createdAt);
  const [highlow, setHighlow] = useState(highlowKey.low);
  const [index, setIndex] = useState(2);
  const [activeSort, setActiveSort] = useState('active');

  /** 新規M-LOOPユーザー登録 */
  const handleClickAddUser = useCallback(
    () => {
      IspLogOperation('ispUserAddClick', () => {
        const path = RoutingPath.adminInvitationUser;
        const query = QueryParamFormatter.queryCompose({ p: currentPage });
        dispatch(push(path + '?' + query));
      });
    },
    [],
  );
  /** 退職者表示 */
  const handleClickShowRetire = useCallback(
    () => {
      setIsCheckRetire(!isCheckRetire);
    },
    [isCheckRetire],
  );

  const handlerClickEdit = useCallback((data: typeof userList[0]) => {
    IspLogOperation('ispUserEditClick', () => {
      const path = RoutingPath.adminUserEdit
        .replace(':userId', data.user_id);
      const query = QueryParamFormatter.queryCompose({ p: currentPage });
      dispatch(push(path + '?' + query));
    });
  }, [currentPage, dispatch]);

  /** 表示するページをセット */
  const handlePaginate = useCallback((page: number) => {
    setCurrentPage(page * perData);
  }, [currentPage]);
  /** M-LOOPユーザー一覧取得 */
  useEffect(() => {
    let unmounted = false;
    if (!userInfo.user_id) {
      return;
    }
    if (queryFlag) {
      const query = QueryParamFormatter.queryParse().p;
      setCurrentPage(query ? Number(query) : currentPage);
    } else {
      setCurrentPage(currentPage);
    }
    (apiAdmin.users().get({
      is_retirement: isCheckRetire,
      sort_by: sortBy,
      highlow: highlow,
      limit: 20,
      page: currentPage + 1,
    }) as Promise<ApiGetAdminUsersResponse>)
      .then((v) => {
        if (!unmounted) {
          const body: AdminUserBody = v.body as AdminUserBody;
          setUserList((v.body.data ?? []) as unknown as AdminUserData[]);
          setIspUserBody(v.body as AdminUserBody);
          if (body.current_page > body.last_page) {
            setCurrentPage(body.last_page - 1);
          }
        }
      });
    setQueryFlag(false);
    return () => {
      unmounted = true;
    };
  }, [sortBy, highlow, isCheckRetire, currentPage]);

  const sortList: SortListType[] = [
    {
      index: 0,
      type: 'up',
      key: sortKey.name,
      active: '',
      callback: () => {
      },
    },
    {
      index: 2,
      type: 'up',
      key: sortKey.createdAt,
      active: '',
      callback: () => {
      },
    },
    {
      index: 3,
      type: 'up',
      key: sortKey.loginAt,
      active: '',
      callback: () => {
      },
    },
  ];

  const sort = useMemo(() => {
    return sortList.filter(elm => elm.index === index);
  }, [sortList]);
  sort[0].type = highlow ? 'down' : 'up';
  sort[0].active = activeSort ? 'active' : '';

  const tableHead = [
    '名前(カナ)',
    'メールアドレス',
    '登録日',
    '最終ログイン',
    'オーナー',
    'ステータス',
    '',
  ];

  const tableBody = userList
    .slice(0, perData)
    .map((v, i) => (
      [
        <>{v.family_name + ' ' + v.name}<br />（{v.family_name_kana + ' ' + v.name_kana}）</>,
        v.email,
        DateFormatter.date2str(new Date(v.created_at)),
        !v.login_at ? '' : DateFormatter.date2str(new Date(v.login_at)),
        v.is_mth_owner ? <div>&#9679;</div> : <div></div>,
        <div>{!v.is_valid ? '退職' : (v.status ? '' : '招待中')}</div>,
        v.status ? <Button
          size="small"
          color="secondary"
          label="変更"
          onClick={() => handlerClickEdit(v)}
        /> : <div></div>,
        v.is_valid ? 'active' : 'stop',
      ]
    ));

  return (
    <div
      id="App"
      className="isp_user"
    >
      <SideBar currentPage="isp-user-list" />
      <div className="main_cnt">
        <Header />
        <div className="inner">
          <BreadcrumbList breadcrumbList={breadcrumbList} />
          <div className="inner">
            <section>
              <header>
                <h2>
                  M-LOOPユーザー一覧
                </h2>
                <div className="count">
                  <span>件数</span>
                  {totalData}
                  <span>件</span>
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <div className="checkbox">
                    <Input
                      type="checkbox"
                      id="showRetire"
                      onClick={handleClickShowRetire}
                    />
                    <label
                      htmlFor="showRetire"
                      style={{ marginRight: '10px' }}
                      className="checked"
                    >退職者を表示する</label>
                  </div>
                  <Button
                    label="M-LOOPユーザー登録"
                    onClick={handleClickAddUser}
                  />
                </div>
              </header>
              <div className="table_box">
                <Table
                  head={tableHead}
                  body={tableBody}
                  alignList={['c', 'l', 'c', 'c', 'c', 'c', 'c']}
                  sortList={sortList}
                  setSortBy={setSortBy}
                  setHighlow={setHighlow}
                  setIndex={setIndex}
                  setActive={setActiveSort}
                />

              </div>
              <Pager
                totalPage={totalPage}
                currentPage={currentPage}
                callback={setCurrentPage}
                onChange={(page) => handlePaginate(page)}
              />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
