import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType } from '../models/action-type';

export type EnvState = {
  type: string,
  mode: string,
  kind: string,
  adminUrl: string,
  companyUrl: string,
  manuelUrl: string,
  startGuideUrl: string,
  adminGuideUrl: string,
  rulesUrl: string,
  isInitialize: boolean,
};

const initialState: EnvState = {
  type: '',
  mode: '',
  kind: '',
  adminUrl: '',
  companyUrl: '',
  manuelUrl: '',
  startGuideUrl: '',
  adminGuideUrl: '',
  rulesUrl: '',
  isInitialize: false,
};

export const serverEnvSlice = createSlice({
  name: 'server-env',
  initialState,
  reducers: {
    set: (
      state,
      action: PayloadAction<EnvState>
    ) => ({
      ...state,
      type: action.payload.type,
      mode: action.payload.mode,
      kind: action.payload.kind,
      adminUrl: action.payload.adminUrl,
      companyUrl: action.payload.companyUrl,
      manuelUrl: action.payload.manuelUrl,
      startGuideUrl: action.payload.startGuideUrl,
      adminGuideUrl: action.payload.adminGuideUrl,
      rulesUrl: action.payload.rulesUrl,
      isInitialize: action.payload.isInitialize,
    }),
  },
});

export const serverEnvAction = serverEnvSlice.actions;
export const serverEnvReducer = serverEnvSlice.reducer;
