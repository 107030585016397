import { Navigate, Route } from "react-router-dom"
import { Routes } from 'react-router';
import { ApiManager } from "../../managers/api-manager"
import { RoutingPath } from "../routing-path";
import { CompanyInvitationUser } from "../../components/pages/company/company-invitation-user"
import { CompanyStatus } from "../../components/pages/company/company-status"
import { CompanyEditUser } from "../../components/pages/company/company-user-edit"
import { CompanyUserList } from "../../components/pages/company/company-user-list"
import ErrorToken from "../../components/pages/error/error-token"
import { InformationAdd } from "../../components/pages/information/information-add"
import { InformationDetail } from "../../components/pages/information/information-detail"
import { InformationList } from "../../components/pages/information/information-list"
import { OperationLog } from "../../components/pages/log/operation-log"
import { Login } from "../../components/pages/login/login"
import PasswordReissue from "../../components/pages/login/password-reissue"
import PasswordReset from "../../components/pages/login/password-reset"
import PasswordResetResult from "../../components/pages/login/password-reset-result"
import { OrgManagement } from "../../components/pages/organization/org-management"
import EditPassword from "../../components/pages/profiles/edit-password"
import { NotificationSettingsCompany } from "../../components/pages/information/notification-settings-company";
import { Articles } from "../../components/pages/articles/articles";
import UserSetting from "../../components/pages/profiles/user-setting";


type Props = {
  apiManager: ApiManager,
}


export const CompanyRoutes = (props: Props) => {
  const { apiManager: apiManagerCompany }  = props;

  return (
    <Routes>
      <Route
        path={RoutingPath.apiCompanyLogin}
        element={<Login apiManger={apiManagerCompany} />}
      />
      <Route
        path={RoutingPath.companyPasswordReissue}
        element={<PasswordReissue apiManger={apiManagerCompany} />}
      />
      <Route
        path={RoutingPath.companyPasswordReset}
        element={<PasswordReset apiManger={apiManagerCompany} />}
      />
      <Route
        path={RoutingPath.companyPasswordResetResult}
        element={<PasswordResetResult apiManger={apiManagerCompany} />}
      />
      <Route
        path={RoutingPath.companyOrgManagement}
        element={<OrgManagement apiManger={apiManagerCompany} />}
      />
      <Route
        path={RoutingPath.companyUserList}
        element={<CompanyUserList apiManger={apiManagerCompany} />}
      />
      <Route
        path={RoutingPath.companyInvitationUser}
        element={<CompanyInvitationUser apiManger={apiManagerCompany} />}
      />
      <Route
        path={RoutingPath.companyUserEdit}
        element={<CompanyEditUser
          apiManger={apiManagerCompany}
        />}
      />
      <Route
        path={RoutingPath.companyStatus}
        element={<CompanyStatus apiManger={apiManagerCompany} />}
      />
      <Route
        path={RoutingPath.companyErrorToken}
        element={<ErrorToken />}
      />
      <Route
        path={RoutingPath.companyUserSetting}
        element={<UserSetting />}
      />
      <Route
        path={RoutingPath.companyEditPassword}
        element={<EditPassword apiManger={apiManagerCompany} />}
      />

      <Route
        path={RoutingPath.companyLogExport}
        element={<OperationLog apiManger={apiManagerCompany} />}
      />
      <Route
        path={RoutingPath.companyInfo}
        element={<InformationList apiManger={apiManagerCompany} />}
      />
      <Route
        path={RoutingPath.companyInfoAdd}
        element={<InformationAdd
          apiManger={apiManagerCompany}
          mode={'add'}
        />}
      />
      <Route
        path={RoutingPath.companyInfoClone}
        element={<InformationAdd
          apiManger={apiManagerCompany}
          mode={'clone'}
        />}
      />
      <Route
        path={RoutingPath.companyInfoDetail}
        element={<InformationDetail apiManger={apiManagerCompany} />}
      />
      <Route
        path={RoutingPath.companyInfoSelect}
        element={<NotificationSettingsCompany apiManger={apiManagerCompany} />}
      />
      <Route
        path={RoutingPath.companyArticles}
        element={<Articles apiManger={apiManagerCompany} />}
      />
    <Route
      path={'/'}
      element={
        <Navigate
          to={RoutingPath.apiCompanyLogin}
        />
      }
    />
    <Route
      path={'*'}
      element={
        <Navigate
          to={RoutingPath.apiCompanyLogin}
        />
      }
    />

    </Routes>
  )
}