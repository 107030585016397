import con from '../../../connection';
import occupations from './occupations';
import buildingtypes from './buildingtypes';

const path = '/common';

const common = (url: string) => () => ({
  occupations: occupations(url + path),
  buildingtypes: buildingtypes(url + path),
});

export default common;
