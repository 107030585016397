import con from "../../../../connection";
import authoritiesDetail from './detail/index';

const path = '/authorities';

type GetRequestMasterAuthorities = {
  /**
   * 1:種別 2:system名 3:説明 4:バージョン 5:表示順（デフォルト） 6:更新日
   */
  sort_by?: number,
  highlow?: number,
}

export type GetResponseMasterAuthoritiesDataEntity = {
  /** ID */
  id:	number,
  /** "権限種別 1:共通 2:機能別" */
  kind:	number,
  /** システム名 */
  system:	string,
  /** 説明 */
  description:	string,
  /** ファイル名 */
  filename:	string,
  /** バージョン */
  version:	string,
  /** 権限更新日 */
  update_date:	Date,
  /** 権限定義 */
  definition:	{[key in string]: any},
  /** 表示順 */
  sort_order:	number,
  /** 登録日時 */
  created_at:	string,
  /** 更新日時 */
  updated_at:	string,
}

export type PostRequestNewMasterAuthority = {
  /** 種別 １：共通 ２：機能別 */
  kind: 1 | 2,
  /** 並び順 */
  sort_order: number,
  /** jsonファイル */
  File: File,
}

const authorities = (url: string) => () => {
  return {
    get: (request: GetRequestMasterAuthorities) => con.get<{}>(`${url}${path}`, request),
    post: (request: PostRequestNewMasterAuthority) => con.post<{}>(`${url}${path}`, request),
    detail: authoritiesDetail(`${url}${path}`),
  };
};

export default authorities;

