import con from '../../../connection';
import operations from './operations';

const path = '/logs';

const logs = (url: string) => () => ({
  operations: operations(url + path),
});

export default logs;
