import { OptionsType } from '../..';
import con from '../../../../connection';

const path = '/buildingtypes';

const buildingtypes = (url: string) => () => ({
  get: (options?: OptionsType) => con.get<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
    body: {
      data: {
        master: string[],
      }[],
    },
  }>(url + path, {}, options?.noError, options?.noNetworkError),
});

export default buildingtypes;
