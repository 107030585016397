import { apiCompany, ApiManager } from '../../managers/api-manager';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CompanyOrganizationSlicerState = {};

const initialState: CompanyOrganizationSlicerState = {};


export const apiCompanyOrganizationsSlice = createSlice({
  name: 'api-company-organizations',
  initialState,
  reducers: {
    register: (state, action: PayloadAction<{
      param: {
        organization_name: string;
        is_not_address_copy: boolean;
        zip_code: string;
        pref_id: number;
        address: string;
        others: string;
        parent_organization_code: string;
        parent_hierarchy: number;
      },
      callback: {
        success: () => void,
      },
    }>) => {
      apiCompany.organizations().post(action.payload.param)
        .then ((res) => {
          action.payload.callback.success();
        })
        .catch((e) => {
          ApiManager.errorFunc(e);
        });
    },
    update: (state, action: PayloadAction<{
      organization_code: string,
      param: {
        organization_name: string;
        is_not_address_copy: boolean;
        zip_code: string;
        pref_id: number;
        address: string;
        others: string;
      },
      callback: {
        success: () => void,
      },
    }>) => {
      apiCompany.organizations(action.payload.organization_code).post(action.payload.param)
        .then ((res) => {
          action.payload.callback.success();
        })
        .catch((e) => {
          ApiManager.errorFunc(e);
        });
    },
  },
});

export const apiCompanyOrganizationsAction = apiCompanyOrganizationsSlice.actions;
export const apiCompanyOrganizationsReducer = apiCompanyOrganizationsSlice.reducer;