import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationDetaEntity } from '../components/pages/information/type/infomation.type';
import { OutputData } from '@editorjs/editorjs';

export type InfoParams = {
  title: string;
  body: OutputData | string | undefined;
  level: number;
  category: number;
  isUrl: number;
  url: string;
  notificationStartDate: Date | null;
  notificationEndDate: Date | null;
  senderName: string | null;
}
export type InfoInitParams = {
  title: boolean;
  body: boolean;
  url: boolean;
  category: boolean;
  notificationStartDate: boolean;
  notificationEndDate: boolean;
  senderName: boolean;
}
export type InformationSliceState = {
  informationData: InfoParams,
  senderList: {
    companyCode: string;
    memberId: string;
  }[];
  sendList: NotificationDetaEntity[];
};

export const InformationDefaultData: InfoParams = {
  title: '',
  body: undefined,
  level: 0,
  category: 0,
  isUrl: 0,
  url: '',
  notificationStartDate: null,
  notificationEndDate: null,
  senderName: null,
};

const initialState: InformationSliceState = {
  informationData: InformationDefaultData,
  senderList: [],
  sendList: [],
};

export const informationSlice = createSlice({
  name: 'information',
  initialState,
  reducers: {
    setInformationData: (
      state,
      action: PayloadAction<InfoParams>,
    ) => {
      state.informationData = action.payload;
    },
    setSenderList: (state, action: PayloadAction<{
      companyCode: string,
      memberId: string,
    }[]> ) => {
      state.senderList = action.payload;
    },
    setSendList: (state, action: PayloadAction<NotificationDetaEntity[]>) => {
      state.sendList = action.payload;
    }
  },
});

export const informationAction = informationSlice.actions;
export const informationReducer = informationSlice.reducer;
