import con from "../../../../../connection";
import { BaseResponseType } from "../../../../../types/base-types";

const path = '/destinations'

export type GetResponseMasterDestinationsDataEntity = {
  persons: {
    /** 企業コード */
    company_code: string,
    /** 組織コード */
    organization_code: string,
    /** 企業名 */
    company_name: string,
    /** 組織名 */
    organization_name: string,
  }[],
  organizations: {
    /** 企業コード */
    company_code: string,
    /** 組織コード */
    organization_code: string,
    /** 企業名 */
    company_name: string,
    /** 組織名 */
    organization_name: string,
  }[],
}

export type PostRequestMasterDestinationsData = {
  /** 種別
   * 1: 個人宛
   * 2: 組織宛
   */
  kind: 1 | 2,
  /** 組織コード
   * 組織コードを配列で
   */
  organizations: string[],
}

export const destinations = (url: string) => () => {
  const fullPath = `${url}${path}`
  return {
    get: () => con.get<BaseResponseType<GetResponseMasterDestinationsDataEntity>>(fullPath, {}),
    post: (param: PostRequestMasterDestinationsData) => con.post<{}>(fullPath, param)
  };
};

export default destinations;