import { API, BlockToolConstructorOptions, InlineTool } from '@editorjs/editorjs';

export class TestInlineTool implements InlineTool {

  state: boolean;
  private tag: 'B' = 'B';
  private class = 'plugin:test-bold';
  private button: HTMLButtonElement | null;
  private api: API;

  constructor(config: BlockToolConstructorOptions<{}, {}>) {
    this.state = false;
    this.api = config.api;
    this.button = null;
  }

  static get isInline() {
    return true;
  }

  render() {
    const ele = document.createElement('button');
    ele.innerText = 'B';
    ele.classList.add(this.api.styles.inlineToolButton);
    this.button = ele;
    return ele;
  }

  save() {
  }

  surround(range: Range): void {
    console.groupCollapsed('[surround]');
    console.log('range : ', range);
    console.log('this.state  :', this.state);
    console.groupEnd();
    if (this.state) {
      this.unwrap(range);
    } else {
      this.wrap(range);
    }
  }

  checkState(selection: Selection) {
    const bold = this.api.selection.findParentTag(this.tag);
    this.state = !!bold;
    this.button?.classList.toggle(this.api.styles.inlineToolButtonActive, this.state);
    console.groupCollapsed('[checkState] : ', this.state);
    console.log('bold : ', bold);
    console.log('this.state : ', this.state);
    console.groupEnd();
    return this.state;
  }

  private wrap(range: Range) {
    const text = range.extractContents();
    const bold = document.createElement(this.tag);
    bold.classList.add(this.class);
    bold.appendChild(text);
    range.insertNode(bold);
    this.api.selection.expandToTag(bold);
    console.groupCollapsed('[wrap]');
    console.log('range : ', range);
    console.log('text : ', text);
    console.log('bold : ', bold);
    console.groupEnd();
  }

  private unwrap(range: Range) {
    const bold = this.api.selection.findParentTag(this.tag, this.class);
    const text = range.extractContents();
    bold?.remove();
    range.insertNode(text);
    console.groupCollapsed('[unwrap]');
    console.log('range : ', range);
    console.log('text : ', text);
    console.log('bold : ', bold);
    console.groupEnd();
  }

}
