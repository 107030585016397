import con from '../../../../connection';

const path = '/operations';

const operations = (url: string) => () => ({
  // 操作ログ登録
  post: (param: {
    scene_id: number,
    sub_scene_id?: number,
    sub_scene2_id?: number,
    sub_scene3_id?: number,
    sub_scene4_id?: number,
    sub_scene5_id?: number,
    action_id: number,
  }) => con.post(url + path, param),
  /** ログアウト */
  logout: () => con.post(
    url + path,
    {
      scene_id: 3,
      sub_scene_id: 31,
      action_id: 2,
    },
  ),
  /** 設定-企業設定 */
  companySetting: () => con.post(
    url + path,
    {
      scene_id: 8,
      sub_scene_id: 15,
      action_id: 5,
    },
  ),
  /** 設定-個人設定-表示設定-押下 */
  personalSettingDisplaySettingClick: () => con.post(
    url + path,
    {
      scene_id: 8,
      sub_scene_id: 16,
      sub_scene2_id: 12,
      action_id: 1,
    },
  ),
  /** 設定-個人設定-表示設定-実行 */
  personalSettingDisplaySettingDo: () => con.post(
    url + path,
    {
      scene_id: 8,
      sub_scene_id: 16,
      sub_scene2_id: 12,
      action_id: 5,
    },
  ),
  /** 設定－個人設定－パスワード変更-押下 */
  personalSettingChangePasswordClick: () => con.post(
    url + path,
    {
      scene_id: 8,
      sub_scene_id: 16,
      sub_scene2_id: 13,
      action_id: 1,
    },
  ),
  /** 設定－個人設定－パスワード変更-実行 */
  personalSettingChangePasswordDo: () => con.post(
    url + path,
    {
      scene_id: 8,
      sub_scene_id: 16,
      sub_scene2_id: 13,
      action_id: 5,
    },
  ),
});

export default operations;
