import { OptionsType } from '../../';
import con from '../../../../connection';

const path = '/members';

const members = (url: string) => () => ({
  post: <TResponse> (
    param: {
      members: string[],
    },
    options?: OptionsType,
  ) => con.post<TResponse>(url + path, param, options?.noError, options?.timeoutMS),
});

export default members;
