import { OptionsType } from '..';
import con from '../../../connection';

const path = '/password';

const password = (url: string) => () => ({
  // パスワードリセット
  reset: (param: {
    email: string,
  }) => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
  }>(url + path + '/reset', param),
  // パスワード再設定ポーリング
  polling: (param: {
    token: string,
  }, options?: OptionsType) => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
  }>(url + path + '/polling', param, options?.noError),
  // パスワードリセットトークン認証
  auth: (param: {
    token: string,
  }) => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
    body: {
      user_id: string,
    },
  }>(url + path + '/auth', param),
  // パスワード再設定
  set: (param: {
    /** パスワードリセット時に発行されたトークンを使用 */
    token: string,
    /** パスワードリセットトークン認証成功時に返却されたIDを使用 */
    user_id: string,
    password: string, 
  }) => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
  }>(url + path + '/set', param),
});

export default password;
