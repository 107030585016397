import con from '../../../../connection';

const path = '/activate';

const activate = (url: string) => () => {
  return {
    post: (param: {
      token: string;
      kind: boolean;
      member_id: string;
      email: string;
      is_owner: boolean;
      family_name?: string;
      name?: string;
      family_name_kana?: string;
      name_kana?: string;
      password?: string;
    }) =>
      con.post<{
        header: {
          status: string;
          status_code: number;
          messages: string[];
        };
        body: {
          data: {
            company_code: string;
            member_id: string;
            email: string;
            organization_code: string;
            occupation_id: number;
            industry_id: number;
            display_name: string;
            role: number;
            is_owner: boolean;
            is_information: boolean;
            is_log_admin: boolean;
            is_cadect1: boolean;
            is_cadect2: boolean;
            is_cadect3: boolean;
            is_cadect4: boolean;
            is_cadect5: boolean;
            is_valid: boolean;
            created_at: string;
            updated_at: string;
            company_name: string;
            organization_name: string;
          };
        };
      }>(url + path, param),
  };
};

export default activate;
