import con from '../../../../connection';

const path = '/set';

const set = (url: string) => () => {
  return {
    post: (param: {
      token: string,
      user_id: string,
      password: string,
      }) =>
      con.post<{
        header: {
          status: string;
          status_code: number;
          messages: string[];
        };
        body: {
          data: [];
        };
      }>(url + path, param),
  };
};

export default set;