import { members } from './members';
import con from '../../../connection';
import { ApiGetCompaniesResponse } from './api-get-companies.response';
import { ApiGetCompanyDetailResponse } from './api-get-company-detail.response';
import { organizations } from './organizations';
import portals from './portals/index';
import reuse from './reuse';
import { check } from './check/index';
import operation from './operation';
import authorities from './authorities';

const path = '/companies';

const companies = (url: string) => (company_code?: string) => {
  return {
    check: check(url + path),
    members: members(`${url}${path}/${company_code}`),
    organizations: organizations(`${url}${path}/${company_code}`),
    portals: portals(`${url}${path}/${company_code}`),
    reuse: reuse(`${url}${path}/${company_code}`),
    operation: operation(`${url}${path}/${company_code}`),
    authorities: authorities(`${url}${path}`),
    get: <
      ResponseType extends ApiGetCompaniesResponse | ApiGetCompanyDetailResponse,
    >(param?: {
      is_view?: boolean;
      sort_by?: number;
      highlow?: number;
      limit?: number;
      page?: number;
    }) =>
      !company_code && param
        ? con.get<ResponseType>(url + path, param)
        : con.get<ResponseType>(`${url}${path}/${company_code}`, {}),
    post: (param: {
      company_code: string;
      company_name: string;
      company_name_kana: string;
      industry_id: number;
      occupation_id: number;
      zip_code: string;
      pref_id: number;
      address: string;
      others: string;
      tel: string;
      storage_size: number;
      member_use_count: number;
      info_use_count: number;
      log_use_count: number;
      cadect1_use_count: number;
      cadect2_use_count: number;
      cadect3_use_count: number;
      cadect4_use_count: number;
      scheduled_to_stop_at?: string | null;
      pass: string;
      is_mfa?: boolean;
      authorities: string,
    }) =>
      con.post<{
        header: {
          status: string;
          status_code: number;
          messages: string[];
        };
        body: {
          data: [];
        };
      }>(url + path + (company_code ? `/${company_code}` : ''), param),
  };
};

export default companies;
