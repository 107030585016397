export const ADMIN_COMPANY_NAME = 'Panasonic';

export const WEBCAD_APP_NAME = 'プラン';

type CompanyFlagEntity = { label: string, value: number };
export const COMPANY_FLAG: CompanyFlagEntity[] = [
  { label: '販売店様、工務店様、設計事務所様、個人等', value: 1 },
  { label: '代理店様', value: 2 },
  { label: 'Panasonic（営業、MD・SE）', value: 3 },
  { label: 'Panasonic（プランセンター）', value: 4 },
];

export const COMMON_APP_NAME = 'M-LOOP';
export const ADMIN_APP_NAME = 'M-LOOP管理Web';
export const COMPANY_APP_NAME = '企業管理Web';
export const PORTAL_APP_NAME = 'ポータル';
export const ADMIN_HEADER_NAME = 'M-LOOP管理Web';
export const COMPANY_HEADER_NAME = '企業管理Web';