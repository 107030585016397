import con from '../../../connection';
import send from './send';

const path = '/requests';

const requests = (url: string) => (request_id?: string) => ({
  get: (param?: {
    kind?: number,
    article_name?: string,
    sort_by?: number,
    highlow?: number, 
    limit?: number,
    offset?: number,
  }) => request_id
    // 依頼詳細取得
    ? con.get(url + path + "/" + request_id, {})
    // 依頼一覧取得
    : con.get(url + path, param || {}),
  send: send(`${url}${path}`),
});

export default requests;
