import { Navigate, Route } from "react-router-dom"
import { Routes } from 'react-router';
import { Authorization } from "../../components/pages/authorization/authorization"
import { CompanyAdd, CompanyEdit } from "../../components/pages/company/company-add"
import { CompanyDetail } from "../../components/pages/company/company-detail"
import { CompanyInvitationUser } from "../../components/pages/company/company-invitation-user"
import { CompanyList } from "../../components/pages/company/company-list"
import { CompanyStatus } from "../../components/pages/company/company-status"
import { CompanyEditUser } from "../../components/pages/company/company-user-edit"
import { CompanyUserList } from "../../components/pages/company/company-user-list"
import ErrorToken from "../../components/pages/error/error-token"
import { InformationAdd } from "../../components/pages/information/information-add"
import { InformationDetail } from "../../components/pages/information/information-detail"
import { InformationList } from "../../components/pages/information/information-list"
import { NotificationSettings } from "../../components/pages/information/notification-settings"
import { OperationLog } from "../../components/pages/log/operation-log"
import { Login } from "../../components/pages/login/login"
import PasswordReissue from "../../components/pages/login/password-reissue"
import PasswordReset from "../../components/pages/login/password-reset"
import PasswordResetResult from "../../components/pages/login/password-reset-result"
import { OrgManagement } from "../../components/pages/organization/org-management"
import { Portal } from "../../components/pages/portal/portal"
import EditPassword from "../../components/pages/profiles/edit-password"
import { RequestSetting } from "../../components/pages/request-setting/request-setting"
import { AdminUserAdd } from "../../components/pages/user/admin-user-add"
import { AdminUserAddCheck } from "../../components/pages/user/admin-user-add-check"
import { AdminUserEdit } from "../../components/pages/user/admin-user-edit"
import { AdminUserList } from "../../components/pages/user/admin-user-list"
import { ApiManager } from "../../managers/api-manager"
import { RoutingPath } from "../routing-path"

type Props = {
  apiManager: ApiManager,
}

export const AdminRoutes = (props: Props) => {
  const { apiManager: apiManagerAdmin }  = props;
  return (
    <Routes>
      <Route
      path={RoutingPath.apiAdminLogin}
      element={<Login apiManger={apiManagerAdmin} />}
    />
    <Route
      path={RoutingPath.adminPortal}
      element={<Portal apiManger={apiManagerAdmin} />}
    />
    <Route
      path={RoutingPath.adminCompanyList}
      element={<CompanyList apiManger={apiManagerAdmin} />}
    />
    <Route
      path={RoutingPath.adminEditPassword}
      element={<EditPassword apiManger={apiManagerAdmin} />}
    />
    <Route
      path={RoutingPath.adminPasswordReissue}
      element={<PasswordReissue apiManger={apiManagerAdmin} />}
    />
    <Route
      path={RoutingPath.adminPasswordReset}
      element={<PasswordReset apiManger={apiManagerAdmin} />}
    />
    <Route
      path={RoutingPath.adminPasswordResetResult}
      element={<PasswordResetResult apiManger={apiManagerAdmin} />}
    />
    <Route
      path={RoutingPath.adminOrgManagement}
      element={<OrgManagement
        apiManger={apiManagerAdmin}
      />}
    />
    <Route
      path={RoutingPath.adminUserList}
      element={<AdminUserList apiManger={apiManagerAdmin} />}
    />
    <Route
      path={RoutingPath.adminInvitationUser}
      element={<AdminUserEdit apiManger={apiManagerAdmin} />}
    />
    <Route
      path={RoutingPath.adminUserAdd}
      element={<AdminUserAdd apiManger={apiManagerAdmin} />}
    />
    <Route
      path={RoutingPath.adminUserAddCheck}
      element={<AdminUserAddCheck
        apiManger={apiManagerAdmin}
        isCompanyUser={false}
      />}
    />
    <Route
      path={RoutingPath.adminUserEdit}
      element={<AdminUserEdit
        apiManger={apiManagerAdmin}
      />}
    />
    <Route
      path={RoutingPath.adminCompanyAdd}
      element={<CompanyAdd apiManger={apiManagerAdmin} />}
    />
    <Route
      path={RoutingPath.adminCompanyDetail}
      element={<CompanyDetail
        apiManger={apiManagerAdmin}
        // companyCode={companyCode!}
      />}
    />
    <Route
      path={RoutingPath.adminCompanyEdit}
      element={<CompanyEdit
        apiManger={apiManagerAdmin}
      />}
    />
    <Route
      path={RoutingPath.adminCompanyUserList}
      element={<CompanyUserList
        apiManger={apiManagerAdmin}
      />}
    />
    <Route
      path={RoutingPath.adminCompanyInvitationUser}
      element={<CompanyInvitationUser
        apiManger={apiManagerAdmin}
      />}
    />
    <Route
      path={RoutingPath.adminCompanyUserEdit}
      element={<CompanyEditUser
        apiManger={apiManagerAdmin}
      />}
    />
    <Route
      path={RoutingPath.adminCompanyStatus}
      element={<CompanyStatus
        apiManger={apiManagerAdmin}
      />}
    />
    <Route
      path={RoutingPath.adminErrorToken}
      element={<ErrorToken />}
    />
    <Route
      path={RoutingPath.adminLogExport}
      element={<OperationLog apiManger={apiManagerAdmin} />}
    />
    <Route
      path={RoutingPath.adminInfo}
      element={<InformationList apiManger={apiManagerAdmin} />}
    />
    <Route
      path={RoutingPath.adminInfoAdd}
      element={<InformationAdd
        apiManger={apiManagerAdmin}
        mode={'add'}
      />}
    />
    <Route
      path={RoutingPath.adminInfoClone}
      element={<InformationAdd
        apiManger={apiManagerAdmin}
        mode={'clone'}
      />}
    />
    <Route
      path={RoutingPath.adminInfoDetail}
      element={<InformationDetail apiManger={apiManagerAdmin} />}
    />
    <Route
      path={RoutingPath.adminInfoSelect}
      element={<NotificationSettings apiManger={apiManagerAdmin} />}
    />
    <Route
      path={'/'}
      element={
        <Navigate
          to={RoutingPath.apiAdminLogin}
        />
      }
    />
    <Route
      path={'*'}
      element={
        <Navigate
          to={RoutingPath.apiAdminLogin}
        />
      }
    />
  
    </Routes>
  )
}