import { TreeView } from '../../models/tree-view';
import { Button } from '../ui/button/button';
import { Organization } from '../../models/edit-tree-view/models';
import { useCallback, useState } from 'react';
import { dialogAction } from '../../slices/dialog-slice';
import { Leaf } from '../../models/tree-view/models';
import rangeOff from '../../assets/images/common/range_off.svg';
import rangeOn from '../../assets/images/common/range_on.svg';
import zoomInOff from '../../assets/images/common/zoom-in_off.svg';
import zoomInOn from '../../assets/images/common/zoom-in_on.svg';
import zoomOutOff from '../../assets/images/common/zoom-out_off.svg';
import zoomOutOn from '../../assets/images/common/zoom-out_on.svg';
import { useDidMount } from '../../hooks/life-cycle';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { IspLogOperation } from '../../models/logs/isp-log-operation';
import { CompanyLogOperation } from '../../models/logs/company-log-operation';

type OrganizationSelectDialogProps = {
  callback: (v: { id: string, label: string }) => void,
  orgData: Organization[],
  selectOrg: string,
  mode: 'add' | 'edit'
  disabledOrg?: string,
  isCanselPop?: boolean,
  isConfirm?: boolean,
  isOrgMarge?: boolean,
};

export const OrganizationSelectDialog = (props: OrganizationSelectDialogProps) => {
  const dispatch = useAppDispatch();
  const {type} = useAppSelector((state) => state.serverEnv)
  const { callback, orgData,selectOrg, mode, disabledOrg, isCanselPop, isConfirm, isOrgMarge } = props;
  const [value, setValue] = useState<Leaf | null>(null);
  const [data, setData] = useState<Organization[]>([]);
  // 現在、選択されているリーフ
  const leafList: Leaf[] = []
  const handlerChangeValue = useCallback((v: Leaf) => {
    // if (leafList.length > 0 ) {
    //   leafList.forEach((l) => {
    //     l.isActive = false;
    //   })
    //
    // }
    if (leafList.length > 0) {
      leafList[0].isActive = false
      leafList.length = 0
    }
    // 選択したリーフのみ色を変える
    if (v) {
      const checkParent = (l: Leaf) => {
        leafList.push(l)
        // if (l.children) {
        //   l.children?.forEach((child: Leaf) => {
        //     child.isActive = true;
        //   checkParent(child);
        //   })
        // }
        l.isActive = true;
      };
      checkParent(v);
    }
    // if (v) {
    //   leafList.push(v)
    //   v.isActive = true
    // }
    // v.isActive = true;
    setValue(v);
  }, [leafList])
  const handlerClickCancel = useCallback(() => {
    dispatch(dialogAction.pop());
  }, []);
  const handlerClickOk = useCallback(() => {
    if (value) {
      callback(value);
    }
    isCanselPop || dispatch(dialogAction.pop());
  }, [value]);
  useDidMount(() => {
    const _orgData = orgData.filter((v: any) => {
      return v.is_valid === true;
    });
    // if (selectOrg) {
    //   const _selectOrg = _orgData.filter((v: any) => {
    //     return v.organization_code === selectOrg.id
    //   })
    // }
    setData(_orgData as any);
  })
  return (
    <div className="organization_tree">
      <div className="tree_wrap">
        <TreeView
          organizations={data}
          selectOrg={selectOrg}
          disabledOrg={disabledOrg}
          onClick={handlerChangeValue}
          isOrgMarge={isOrgMarge}
        />
      </div>
      {/* TODO 6以降 */}
      {/*<div className="map">/!*★TODO*!/*!/*/}
      {/*  <div className="frame" style={{ width: "250px", height: "250px" }}>*/}
      {/*    <div className="line" style={{ width: "100px", height: "100px", top: "10px", left: "10px" }}>*/}

      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="btn_box">*/}
      {/*    <button className="on">*/}
      {/*      <img src={rangeOff} alt="" />*/}
      {/*      <img src={rangeOn} alt="" className="on" />*/}
      {/*    </button>*/}
      {/*    <button className="">*/}
      {/*      <img src={zoomInOff} alt="" />*/}
      {/*      <img src={zoomInOn} alt="" className="on" />*/}
      {/*    </button>*/}
      {/*    <button className="">*/}
      {/*      <img src={zoomOutOff} alt="" />*/}
      {/*      <img src={zoomOutOn} alt="" className="on" />*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="btn_box">
        <Button
          size="medium"
          color="tertiary"
          label={isConfirm ? '戻る' : 'キャンセル'}
          onClick={handlerClickCancel}
        />
        <Button
          size="medium"
          label={isConfirm ? '確認' : 'OK'}
          onClick={handlerClickOk}
        />
      </div>
    </div >
  );
};
