import con from '../../../../connection';

const path = '/industries';

export const industries = (url: string) => (type?: 1 | 2) => ({
  get: () => con.get<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
    body: {
      data: {
        id: number,
        name: string,
        is_valid: boolean,
      }[],
    },
  }>(url + path + (type ? `/${type}` : ''), {}),
})