import con from '../../../connection';

const path = '/islogin';
const islogin = (url: string) => () => ({
  get: () =>
    con.get<{
      header: {
        status: string;
        status_code: number;
        messages: string[];
      };
      body: {
        data: [];
      };
    }>(url + path, {}, true),
});

export default islogin;
