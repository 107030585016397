import { Skeleton } from '../skeleton/skeleton';

type Props = {
  str: string;
};

export const Label = (props: Props) => {
  const { str } = props;
  let _str: boolean = true;
  // スケルトン時に無視したい文字を追加する
  switch (str) {
    case '':
      _str = false
      break;
    case '人':
      _str = false
      break;
    case '〒':
      _str = false
      break;
    case 'GB':
      _str = false
      break;
    case ' ':
      _str = false
      break;
    default:
      break
  }
  const loading = _str ? Boolean(str) : _str;
  return loading ? (
    <span className="label">{str}</span>
  ) : (
    <Skeleton />
  );
};

type SkeletonProps = {
  str: string;
  isLoading: boolean;
  initLabel?: string;
}
export const SkeletonLabel = (props: SkeletonProps) => {
  const { str, isLoading, initLabel = '' } = props;
  // スケルトン時に無視したい文字を追加する
  return isLoading ? (
    <span>{str || initLabel}</span>
  ) : (
    // <Skeleton />
    <span>{''}</span>
  );
};
