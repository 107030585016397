import React, { useCallback } from 'react';
import { Button } from '../../ui/button/button';
import { RoutingPath } from '../../../routes/routing-path';
import { push } from '@lagunovsky/redux-react-router';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { useAppDispatch } from '../../../app/hooks';

const PasswordResetResult = (props: PageComponentDefaultProps) => {
  const { apiManger } = props;
  const dispatch = useAppDispatch();
  const handleClickOk = useCallback(
    () => {
      const path = apiManger.type === 'company'
       ? RoutingPath.apiCompanyLogin
       : RoutingPath.apiAdminLogin;
      // ログインへ遷移
      dispatch(push(path));
    },
    [],
  );

  return (
    <>
      <div className="dialog_wrap direct_access password">
        <div className="dialog">
          <header>
            パスワードの再設定が完了しました。
          </header>
          <div className="dialog_body">
            <div className="text_box">
              新しいパスワードを設定いたしました<br />
              下のボタンよりお戻りください
            </div>
            <footer>
              <Button
                size="large"
                label={'TOPへ戻る'}
                onClick={handleClickOk}
              />
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordResetResult;
