import password from './password/index';
import con from '../../../connection';

const path = '/profiles';

const profiles = (url: string) => () => ({
  password: password(`${url}${path}`),
  post: (param: {
    display_name: string,
  }) => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
    body: {
      data: [];
    };
  }>(`${url}${path}`, param),
})

export default profiles;