import con from '../../../connection';
import files from './files';
import v2 from './v2';
import takenaka from './takenaka';
import { OptionsType } from '..';
import settings from './settings';
import confirmed from './confirmed';

const path = '/applications';

const applications = (url: string) => (app_id?: string) => ({
  files: files(`${url}${path}`),
  v2: v2(`${url}${path}`),
  takenaka: takenaka(`${url}${path}`),
  settings: settings(`${url}${path}`),
  confirmed: confirmed(`${url}${path}`),
  get: app_id
    // 個別取得
    ? (plan_id: string) => con.get(url + path + "/" + plan_id + "/" + app_id, {})
    // 一覧取得
    : (plan_id: string) => con.get(url + path + "/" + plan_id, {}),
  start: (plan_id: string, app_kind: string) => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
    body: {
      data: {
        token: string,
      },
    }
  }>(
    url + path + "/" + "start" + "/" + plan_id + "/" + (app_id || "") + "/" + app_kind,
    {}
  ),
  end: (token: string) => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    }
  }>(
    url + path + "/" + "end" + "/" + token,
    {}
  ),
  delete: (plan_id: string) => con.delete<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    }
  }>(`${url}${path}/${app_id}/${plan_id}`, {}),
  status: (token: string) => ({
    get: (noError: boolean = false) => con.get<{
      header: {
        status: string,
        status_code: number,
        messages: string[],
      }
    }>(url + path + "/" + token + "/" + "status", {}, noError),
  }),
  report: (
    token: string,
    param: {
      /**
       * **エラー検出種別**
       * - 1:アップロードエラー
       * - 2:ダウンロードエラー
       * - 3:アプリ連携起動失敗
       */
      kind: number,
      /**
       * **ファイル**
       * エラー検出種別が 1:アップロードエラー, 3:アプリ連携起動失敗, の場合は必須
       * アップロードを試みたファイルをセットする
       */
      Filedata?: File,
      /**
       * **エラーコード**
       * ブランクの場合あり
       */
      error_code: string
    },
    options: OptionsType) => con.post<{
    header: {
      /** 値[SUCCESS:成功/FAILED:失敗/UNAUTHORIZED:認証エラー] */
      status: string,
      /**
       * - 200:正常終了
       * - 400:パラメータエラー（未設定など）
       * - 401:認証エラー
       * - 500:Internal Server Error
       */
      status_code: number,
      messages: { message: string }[],
    }
  }>(url + path + '/' + token + '/' + 'report', param, options.noError, options.timeoutMS),
  /**
   * アプリケーション連携データバージョンアップ (DATA API側バージョンアップ)
   * 
   * プランからソフトウェアランチャメニューを開く前に叩いて、連携データのバージョンを最新にするための API
   */
  post: (planId: string, options: OptionsType) => con.post((url + path + '/' + planId), {}, options.noError, options.timeoutMS)
});

export default applications;
