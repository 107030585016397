
type Props = {
  test: (v: string) => boolean,
  errorMessages: string[],
  onValid?: () => void,
  onInvalid?: () => void,
  valid?: boolean
};

export class Validation {

  valid: boolean;
  readonly test: (v: string) => boolean;
  readonly errorMessages: string[];

  private readonly onValid: () => void;
  private readonly onInvalid: () => void;

  constructor(props: Props) {
    this.valid = props.valid ?? true;
    this.errorMessages = props.errorMessages;
    this.onValid = props.onValid ?? (() => {});
    this.onInvalid = props.onInvalid ?? (() => {});
    this.test = (v: string) => {
      const result = props.test(v);
      this.valid = result;
      if (result) {
        this.onValid();
      } else {
        this.onInvalid();
      }
      return result;
    };
  }
}
// 全体チェック用
 export const CheckAllValid = (
 validations: { [key: string]: Validation}
 ) => Boolean(Object.values(validations).filter((v) => !v.valid).length);

