import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type IspUserSliceState = {
  userList: {
    id: string;
    name: string;
    nameKana: string;
    mail: string;
    createAt: string;
    lastLogin: string;
    owner: boolean;
    status: string;
  }[];
  userData: {
    token: string;
    userId: string;
    familyName: string;
    fastName: string;
    familyNameKana: string;
    fastNameKana: string;
    mail: string;
    mailConfirm: string;
    password: string;
    _password: string;
  };
};

export const IspUserDefaultData = {
  token: '',
  userId: '',
  familyName: '',
  fastName: '',
  familyNameKana: '',
  fastNameKana: '',
  mail: '',
  mailConfirm: '',
  password: '',
  _password: '',
};

const initialState: IspUserSliceState = {
  userList: [],
  userData: IspUserDefaultData,
};

export const ispUserSlice = createSlice({
  name: 'isp-user',
  initialState,
  reducers: {
    setData: (
      state,
      action: PayloadAction<{
        token: string;
        userId: string;
        familyName: string;
        fastName: string;
        familyNameKana: string;
        fastNameKana: string;
        mail: string;
        mailConfirm: string;
        password: string;
        _password: string;
      }>,
    ) => {
      state.userData = action.payload;
    },
  },
});

export const ispUserAction = ispUserSlice.actions;
export const ispUserReducer = ispUserSlice.reducer;
