import { ApiManager } from '../managers/api-manager';
import { useAppSelector } from '../app/hooks';
import { AdminRoutes } from './admin-routes/admin-routes';
import { CompanyRoutes } from './company-routes/company-routes';
import { DataRoutes } from './data-routes/data-routes';

const apiManagerAdmin = new ApiManager('admin');
const apiManagerCompany = new ApiManager('company');

export const RoutesConfig = () => {
  const { isInitialize, kind } = useAppSelector((state) => state.serverEnv);
  if (!isInitialize) return <></>
  if (kind === 'admin') return <AdminRoutes apiManager={apiManagerAdmin} />;
  if (kind === 'company') return <CompanyRoutes apiManager={apiManagerCompany} />
  if (kind === 'data') return (
    <DataRoutes
      apiManagerAdmin={apiManagerAdmin}
      apiManagerCompany={apiManagerCompany}
    />
  )

  return <></>
};
