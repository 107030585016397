interface Switch {
  on: () => void,
  off: () => void,
}

export class ControllerEnterKeyEvent implements Switch {
  private static _ins: ControllerEnterKeyEvent;

  private handlerClick:(event: MouseEvent) => void = (event: MouseEvent) => event.stopPropagation();

  private handlerKeyPress:() => void = () => window.document.addEventListener('click', this.handlerClick, true);

  private handlerKeyUp:() => void = () => window.document.removeEventListener('click', this.handlerClick, true);

  private isOn: boolean = true;

  static get ins(): ControllerEnterKeyEvent {
    if (!this._ins) {
      this._ins = new ControllerEnterKeyEvent();
    }
    return this._ins;
  }

  public on(): void {
    if (this.isOn) return;
    this.isOn = true;
    window.document.removeEventListener('keypress', this.handlerKeyPress);
    window.document.removeEventListener('keyup', this.handlerKeyUp);
  }

  public off(): void {
    if (!this.isOn) return;
    this.isOn = false;
    window.document.addEventListener('keypress', this.handlerKeyPress);
    window.document.addEventListener('keyup', this.handlerKeyUp);
  }
}
