import con from "../../../connection";
import authority from "./authorities/index";
import requests from "./requests";

const path = '/master';

const master = (url: string) => () => {
  return {
    authorities: authority(`${url}${path}`),
    requests: requests(`${url}${path}`),
  };
};

export default master;
