import con from '../../../../connection';
import company from './company';

const path = '/operations';

const operations = (url: string) => () => ({
  // 操作ログ登録
  post: (param: {
    scene_id: number,
    sub_scene_id?: number,
    sub_scene2_id?: number,
    sub_scene3_id?: number,
    sub_scene4_id?: number,
    sub_scene5_id?: number,
    action_id: number,
  }) => con.post(url + path, param),
  company: company(url + path),
});

export default operations;
