import {createSlice} from '@reduxjs/toolkit';
import {apiCompany, ApiManager} from '../../managers/api-manager';
import {AppThunk} from '../../app/store';
import {commonDataAction} from '../common-data-slice';

export type CompanyCommonSlicerState = {};

const initialState: CompanyCommonSlicerState = {};

const common = apiCompany.common();

export const apiCompanyCommonSlice = createSlice({
  name: 'api-company-common',
  initialState,
  reducers: {},
});

const asyncAction = {
  getIndustryList: (): AppThunk => async (dispatch) => {
    try {
      const connectionId = ApiManager.connectStart('get-industry-list');
      const res = await common.industries(1).get();
      const industryList: {
        id: number;
        name: string;
        is_valid: boolean;
      }[] = res.body.data;
      dispatch(commonDataAction.setIndustryList(industryList));
      ApiManager.connectEnd(connectionId);
    } catch (e) {
      ApiManager.errorFunc(e);
    }
  },
  getOccupationList: (): AppThunk => async (dispatch) => {
    try {
      const connectionId = ApiManager.connectStart('get-occupation-list');
      const res = await common.occupations().get;
      const occupationList: {
        id: number;
        name: string;
        is_valid: boolean;
      }[] = res.body.data
      dispatch(commonDataAction.setOccupationList(occupationList));
      ApiManager.connectEnd(connectionId);
    } catch (e) {
      ApiManager.errorFunc(e);
    }
  },
};

export const apiCompanyCommonAction = Object.assign(apiCompanyCommonSlice.actions, asyncAction);
export const apiCompanyCommonReducer = apiCompanyCommonSlice.reducer;
