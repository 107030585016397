/* eslint-disable */
export class QueryParamFormatter {

  /**
   * クエリーパラメータのパーサー
   * 任意の文字列を指定することもできる
   * 何も入れなかったら現在のURLをパースする
   * @param {string} str
   * @param {boolean} isDecode
   * @param {boolean} separate
   * @param {string} eq
   * @return {any}
   */
  static queryParse(str: string = '', isDecode: boolean = true, separate?: string, eq?: string): any {
    if (!str) {
      str = window.location.href;
    }
    const value = str.split('?').length === 2 ? str.split('?')[1] : '';
    const text = value || window.location.search.substr(1);
    separate = separate || '&';
    eq = eq || '=';
    const decode = (isDecode) ? decodeURIComponent : (a: string) => a;
    return text.split(separate).reduce((obj, v) => {
      const pair = v.split(eq + '');
      // 配列判定
      if (pair[0].match(/\[\]$/)) {
        // 配列化
        const keyStr = pair[0].split('[')[0];
        if ((obj as any)[keyStr] && Array.isArray((obj as any)[keyStr])) {
          (obj as any)[keyStr].push(decode(pair[1]));
        } else {
          (obj as any)[keyStr] = [decode(pair[1])];
        }
      } else {
        (obj as any)[pair[0]] = decode(pair[1]);
      }
      return pair[0] ? obj : {};
    }, {});
  }

  /**
   * クエリーパラメータ生成器
   * 配列対応
   * @param {any} value
   * @param {boolean} isEncode
   * @param {boolean} separate
   * @param {string} equal
   * @return {string}
   */
  static queryCompose(value: any, isEncode: boolean = true, separate?: string, equal?: string): string {
    const sep: string = separate || '&';
    const eq: string = equal || '=';
    const encode = (isEncode) ? encodeURIComponent : (a: string) => a;
    return Object.keys(value).map((key) => {
      // 配列判定
      if (Array.isArray(value[key])) {
        const keyStr = `${key}[]`;
        return value[key].reduce((str: string, v: any) => `${str}${str ? sep : ''}${keyStr}${eq}${v}`, '');
      }
      return value[key] ? key + eq + encode(value[key]) : '';
    }).filter((v) => v).join(sep);
  }
}
