import { ApiGetCompanyInformationsCategoriesResponse } from './api-get-company-informations-categories.response';
import con from '../../../../connection';

const path = '/categories';

const categories = (url: string) => () => ({
  get: <
   ResponseType extends ApiGetCompanyInformationsCategoriesResponse
  >(param?: {
    is_valid?: number;
  }) => con.get<ResponseType>(url + path, param || {}),
});

export default categories;