import con from '../../../connection';

const path = '/organizations';

const organizations = (url: string) => () => ({
  get: () => (
    // 組織一覧取得
    con.get(`${url}${path}`, {})
  ),
});

export default organizations;
