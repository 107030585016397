import { useCallback } from "react";
import { GetResponseCompanyAuthorities, GetResponseCompanyDetailAuthorityDataEntity } from "../../../../api/api/admin-web/companies/authorities";
import { apiAdmin, ApiManager, apiCompany } from '../../../../managers/api-manager';

export const useGetAuth = () => {
  const getMasterAuth = useCallback(() => new Promise<GetResponseCompanyAuthorities[]>((resolve) => {
    apiAdmin.companies().authorities().get().then((res) => {
      const data = (res as any)?.body.data;
      resolve(data ?? []);
    }).catch((e) => ApiManager.errorFunc(e))
  }), []);

  const getCompanyAuth = useCallback((id: string) => new Promise<GetResponseCompanyDetailAuthorityDataEntity[]>((resolve) => {
    apiAdmin.companies().authorities().getDetail(id).then((res) => {
      const data = (res as any)?.body.data;
      resolve(data ?? []);
    }).catch((e) => ApiManager.errorFunc(e))
  }), []);
  const getCompanyAuthForCompany = useCallback(() => new Promise<GetResponseCompanyAuthorities[]>((resolve) => {
    apiCompany.members().authorities().get().then((res) => {
      const data = (res as any)?.body.data;
      resolve(data ?? []);
    }).catch((e) => ApiManager.errorFunc(e))
  }), []);

  const getMasterUserIspAuth = useCallback((company_code: string) => new Promise<GetResponseCompanyAuthorities[]>((resolve) => {
    apiAdmin.companies(company_code).members().authorities().get()
    .then((res) => {
      const data = (res as any)?.body.data as GetResponseCompanyAuthorities[];
      resolve(data ?? []);
    }).catch((e) => ApiManager.errorFunc(e))
  }), []);

  const getMasterUserRestoreIspAuth = useCallback((company_code: string, member_id: string) => new Promise<GetResponseCompanyDetailAuthorityDataEntity[]>((resolve) => {
    apiAdmin.companies(company_code).members().authorities().getDetail(member_id).then((res) => {
      const data = (res as any)?.body.data as GetResponseCompanyDetailAuthorityDataEntity[];
      resolve(data ?? []);
    }).catch((e) => ApiManager.errorFunc(e))
  }), [])
  const getMasterUserRestoreCompanyAuth = useCallback((member_id: string) => new Promise<GetResponseCompanyDetailAuthorityDataEntity[]>((resolve) => {
    apiCompany.members().authorities().getDetail(member_id).then((res) => {
      const data = (res as any)?.body.data as GetResponseCompanyDetailAuthorityDataEntity[];
      resolve(data ?? []);
    }).catch((e) => ApiManager.errorFunc(e))
  }), [])

  return { getMasterAuth, getCompanyAuth, getMasterUserIspAuth, getMasterUserRestoreIspAuth, getCompanyAuthForCompany, getMasterUserRestoreCompanyAuth }
}