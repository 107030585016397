import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './tooltip.scss';
import { useDidMount } from '../../../hooks/life-cycle';

type AutoPosType = {
  h: 'center' | 'right' | 'left',
  v: 'top' | 'bottom',
};
type DomContentTooltip = {
  element: React.ReactNode,
  relativeEle: HTMLElement | null,
  relativeRef?: RefObject<HTMLElement> | null,
  positionType?: 'fixed' | 'absolute',
  autoPos?: AutoPosType,
  blowing?: boolean,
  error?: boolean,
  edgeSupport?: { ele: globalThis.Window | HTMLElement },
  durationOpacity?: number,
} & React.HTMLAttributes<HTMLDivElement>;
type EdgeSupportType = {
  target: HTMLElement,
  edgeEle: globalThis.Window | HTMLElement,
};
type EdgeInfoType = {
  edgeSideH?: 'right' | 'left',
  edgeSideV?: 'top' | 'bottom',
  diff?: string
};

/**
 * 帰属する要素に対して自動的に位置調整させるツールチップ
 * ※[positionType === "fixed"] のみ調整済み。追加が必要になった都度調整予定。
 *
 * @param {JSX.Element} content ツールチップ内で表示するDOM。
 * @param {HTMLElement | null} relativeEle ツールチップが帰属する要素
 * @param {'fixed' | 'absolute'} positionType ツールチップの固定ルール（※absolute未調整）
 * @param {AutoPosType} autoPos 初期位置の自動配置箇所。デフォルトは左右中央下側。
 * @param {boolean} blowing 吹き出しの有無
 * @param {boolean} error エラー状態であるか
 * @param {globalThis.Window | HTMLElement} edgeSupport 見切れ対応を行うか。また、どの要素に対して見切れ対応をするか。`positionType === "fixed"`のときは`window`を対象とする。
 */
export const Tooltip = (props: DomContentTooltip) => {
  const {
    element,
    relativeEle: _relativeEle,
    positionType = 'fixed',
    autoPos: _autoPos = { h: 'center', v: 'bottom' },
    blowing,
    error,
    edgeSupport: _edgeSupport,
    durationOpacity,
    relativeRef,
    ...defaultProps
  } = props;

  const relativeEle = _relativeEle ?? relativeRef?.current;

  // - State -
  // -- 帰属要素の矩形 --
  const [relEleRect, setRelEleRect] = useState<DOMRect>();
  const [autoPos, setAutoPos] = useState<AutoPosType>({ ..._autoPos });
  // -- 水平方向の基準座標 --
  const [baseHPos, setBaseHPos] = useState<number | string>(0);
  // -- 垂直方向の基準座標 --
  const [baseVPos, setBaseVPos] = useState<number | string>(0);
  // -- 見切れ情報 --
  const [edgeInfo, setEdgeInfo] = useState<EdgeInfoType>({});
  // -- 中央配置させるかどうか --
  const [centeringV, setCenteringV] = useState(false);

  // -- 強制レンダリング --
  const [update, setUpdate] = useState(false);

  // - Ref -
  const ref = useRef<HTMLDivElement>(null);
  const blowingRef = useRef<HTMLDivElement>(null);

  // - Callback -
  // 見切れ対応
  const edgeSupport = useCallback((args: EdgeSupportType) => {
    const { target, edgeEle } = args;
    const eleType = Object.prototype.toString.call(edgeEle).slice(8, -1).toLowerCase();
    const targetRect = target.getBoundingClientRect();
    let diffTop: number | undefined;
    let diffBottom: number | undefined;
    let diffLeft: number | undefined;
    let diffRight: number | undefined;

    const offset = '0.5em';

    if (eleType === 'window') {
      diffTop = targetRect.top;
      diffBottom = window.innerHeight - targetRect.bottom;
      diffLeft = targetRect.left;
      diffRight = window.innerWidth - targetRect.right;
    } else {
      const edgeEleRect = (edgeEle as HTMLElement).getBoundingClientRect();
      diffTop = targetRect.top - edgeEleRect.top;
      diffBottom = edgeEleRect.bottom - targetRect.bottom;
      diffLeft = targetRect.left - edgeEleRect.left;
      diffRight = edgeEleRect.right - targetRect.right;
    }

    if (diffTop < 0 && diffBottom < 0) {
      setCenteringV(true);
      return;
    } else {
      setCenteringV(false);
    }
    if (diffTop < 0) {
      setEdgeInfo({ edgeSideV: 'top', edgeSideH: edgeInfo.edgeSideH, diff: `calc(${-1 * diffTop}px + ${offset})` });
    }
    if (diffBottom < 0) {
      setEdgeInfo({ edgeSideV: 'bottom', edgeSideH: edgeInfo.edgeSideH, diff: `calc(${diffBottom}px - ${offset})` });
    }
    if (diffLeft < 0) {
      setEdgeInfo({ edgeSideV: edgeInfo.edgeSideV, edgeSideH: 'left', diff: `calc(${-1 * diffLeft}px + ${offset})` });
    }
    if (diffRight < 0) {
      setEdgeInfo({ edgeSideV: edgeInfo.edgeSideV, edgeSideH: 'right', diff: `calc(${diffRight}px - ${offset})` });
    }
    return {};
  }, [setEdgeInfo]);

  // - Memo -
  const blowingHeight = useMemo(() => {
    if (blowingRef.current === null) {
      return;
    }
    return blowingRef.current.getBoundingClientRect().height - 5; // -5 ... 既存の素組みツールチップと位置揃えるため
  }, [blowingRef.current?.getBoundingClientRect().height]);

  // - Effect -
  useEffect(() => {
    if (relativeEle) {
      relativeEle.style.position = 'relative';
      setRelEleRect(relativeEle.getBoundingClientRect());
    }
  }, [relativeEle]);
  // -- 座標自動調整 --
  useEffect(() => {
    if (!relEleRect) {
      return;
    }
    ;
    switch (autoPos.h) {
      case 'center':
      default:
        if (positionType === 'fixed') {
          setBaseHPos(relEleRect.x + relEleRect.width / 2);
        } else {
          setBaseHPos('50%');
        }
        break;
      case 'left':
        if (positionType === 'fixed') {
          setBaseHPos(relEleRect.x);
        } else {
          setBaseHPos(0);
        }
        break;
      case 'right':
        if (positionType === 'fixed') {
          setBaseHPos(relEleRect.x + relEleRect.width);
        } else {
          setBaseHPos('100%');
        }
        break;
    }
    switch (autoPos.v) {
      case 'top':
        if (positionType === 'fixed') {
          setBaseVPos(relEleRect.y);
        } else {
          setBaseVPos(0);
        }
        break;
      case 'bottom':
      default:
        if (positionType === 'fixed') {
          setBaseVPos(relEleRect.y + relEleRect.height);
        } else {
          setBaseVPos('100%');
        }
        break;
    }
  }, [autoPos, relEleRect]);
  // -- 水平方向見切れ対応 --
  useEffect(() => {
    if (_edgeSupport && relativeEle && ref.current && relEleRect) {
      edgeSupport({ target: ref.current, edgeEle: positionType === 'fixed' ? window : _edgeSupport.ele });
    }
  }, [relEleRect, baseHPos, baseVPos, setEdgeInfo, ref.current?.getBoundingClientRect().right]);
  // -- 垂直方向見切れ対応 --
  useEffect(() => {
    // 下見切れ
    if (!centeringV && edgeInfo.edgeSideV === 'bottom') {
      setAutoPos({ ..._autoPos, v: 'top' });
    }
    // 上見切れ
    if (!centeringV && edgeInfo.edgeSideV === 'top') {
      setAutoPos({ ..._autoPos, v: 'bottom' });
    }
  }, [centeringV, edgeInfo.edgeSideV]);

  useDidMount(() => {
    if (!relativeRef) return;
    const timer = window.setInterval(() => {
      if (relativeRef.current) {
        clearInterval(timer);
        setUpdate(true);
      }
    }, 300);
  });
  return (
    <div
      {...defaultProps}
      className={`tooltip pos_${autoPos.v}_${autoPos.h} ${positionType}_mode${defaultProps.className ? ` ${defaultProps.className}` : ''}${error ? ' error' : ''}${blowing ? ' has_blowing' : ''}`}
      ref={ref}
      style={{
        position: positionType,
        top: baseVPos,
        left: baseHPos,
        ...positionType === 'absolute' && autoPos.h === 'right' ? { left: 'initial', right: 0 } : {},
        transform: `translate(
          calc(${positionType === 'fixed' && autoPos.h === 'right'
          ? '-100%'
          : autoPos.h === 'center'
            ? '-50%'
            : '0px'
        } + ${edgeInfo.edgeSideH ? edgeInfo.diff : '0px'}),
          calc(${autoPos.v === 'top' ? '-100%' : '0px'}
           + ${blowingHeight ? (autoPos.v === 'bottom' ? 1 : -1) * blowingHeight : 0}px
           )
          )`,
        transition: durationOpacity ? `opacity ${durationOpacity}s` : undefined,
      }}
    >
      {element}
      {blowing && <div
        className={`blowing ${autoPos.v === 'bottom' ? 'up' : autoPos.v === 'top' ? 'down' : autoPos.v} ${autoPos.h}`}
        ref={blowingRef}
        style={{
          transform: `translateX(calc(${edgeInfo.edgeSideH ? `-1 * ${edgeInfo.diff}` : '0px'} - ${autoPos.h === 'center' ? '50%' : '0px'})) rotateZ(${autoPos.v === 'top' ? '180deg' : 0})`,
        }}
      />}
    </div>

  );
};
