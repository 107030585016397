import con from '../../../connection';

const path = '/profiles';

const profiles = (url: string) => (member_id: string) => ({
  get: () => con.get(url + path + "/" + member_id, {}),
  post: (param: {
    company_code: string,
    display_name?: string,
    password?: string,
    old_password?: string,
  }) => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
  }>(url + path + "/" + member_id, param),
});

export default profiles;
