import { ApiGetPortalsResponse } from './api-get-portals.response';
import con from '../../../connection';

const path = '/portals';

const portals = (url: string) => () => ({
  get: () => con.get<ApiGetPortalsResponse>(url + path, {}),
});

export default portals;
