import { IspIdType, logType } from "./log-type";
import { apiAdmin } from "../../managers/api-manager";
import { apiLog } from "./log-data";

const addIspLog = (logData: logType, callback?: () => void) => {
  apiAdmin
    .logs()
    .operations()
    .post({ ...logData })
    .finally(callback);
};

export const IspLogOperation = (id: IspIdType, callback?: () => void) => {
  switch (id) {
    case "portalClick": // ISP管理ポータル押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.ispPortal,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "settingPasswordClick": //
      addIspLog(
        {
          scene_id: apiLog.sceneId.settingMenu,
          sub_scene_id: apiLog.subSceneId.changePassword,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;

    case "companyListClick": // 企業管理－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.companyManagement,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "companyAddClick": // 企業管理－新規登録押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.companyManagement,
          sub_scene_id: apiLog.subSceneId.new,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "companyAddCancel": // 企業管理－新規－キャンセル
      addIspLog(
        {
          scene_id: apiLog.sceneId.companyManagement,
          sub_scene_id: apiLog.subSceneId.new,
          action_id: apiLog.actionId.start,
        },
        callback
      );
      break;
    case "companyDetailClick": // 企業管理－詳細押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.companyManagement,
          sub_scene_id: apiLog.subSceneId.detail,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "companyEditClick": // 企業管理－詳細－企業編集押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.companyManagement,
          sub_scene_id: apiLog.subSceneId.detail,
          sub_scene2_id: apiLog.subSceneId.edit,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "companyEditCancel": // 企業管理－詳細－編集－キャンセル
      addIspLog(
        {
          scene_id: apiLog.sceneId.companyManagement,
          sub_scene_id: apiLog.subSceneId.detail,
          sub_scene2_id: apiLog.subSceneId.edit,
          action_id: apiLog.actionId.start,
        },
        callback
      );
      break;
    case "companyStatusClick": // 企業管理－詳細－状況一覧押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.companyManagement,
          sub_scene_id: apiLog.subSceneId.detail,
          sub_scene2_id: apiLog.subSceneId.statusList,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "organizationManagerClick": // 企業管理－詳細－組織管理押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.companyManagement,
          sub_scene_id: apiLog.subSceneId.detail,
          sub_scene2_id: apiLog.subSceneId.organizationManagement,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "organizationManagerAddClick": // 企業管理－組織管理－新規登録押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.organizationManagement,
          sub_scene_id: apiLog.subSceneId.new,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "organizationManagerAddCheckClick": // 企業管理－組織管理－新規登録－確認押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.organizationManagement,
          sub_scene_id: apiLog.subSceneId.new,
          sub_scene2_id: apiLog.subSceneId.input,
          sub_scene3_id: apiLog.subSceneId.confirm,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "organizationManagerAddCheckCancel": // 企業管理－組織管理－新規登録－戻る押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.organizationManagement,
          sub_scene_id: apiLog.subSceneId.new,
          sub_scene2_id: apiLog.subSceneId.input,
          sub_scene3_id: apiLog.subSceneId.return,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "organizationManagerAddCancel": // 企業管理－組織管理－新規登録－キャンセル押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.organizationManagement,
          sub_scene_id: apiLog.subSceneId.new,
          sub_scene2_id: apiLog.subSceneId.confirm,
          sub_scene3_id: apiLog.subSceneId.cancel,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "organizationManagerEditClick": // 企業管理－組織管理－変更押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.organizationManagement,
          sub_scene_id: apiLog.subSceneId.edit,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "organizationManagerEditCheckClick": // 企業管理－組織管理－編集－確認押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.organizationManagement,
          sub_scene_id: apiLog.subSceneId.edit,
          sub_scene2_id: apiLog.subSceneId.input,
          sub_scene3_id: apiLog.subSceneId.confirm,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "organizationManagerEditCheckCancel": // 企業管理－組織管理－編集－戻る押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.organizationManagement,
          sub_scene_id: apiLog.subSceneId.edit,
          sub_scene2_id: apiLog.subSceneId.input,
          sub_scene3_id: apiLog.subSceneId.return,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "organizationManagerEditCancel": // 企業管理－組織管理－編集－キャンセル押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.organizationManagement,
          sub_scene_id: apiLog.subSceneId.edit,
          sub_scene2_id: apiLog.subSceneId.confirm,
          sub_scene3_id: apiLog.subSceneId.cancel,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "organizationManagerDelete": // 企業管理－組織管理－削除押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.organizationManagement,
          sub_scene_id: apiLog.subSceneId.delete,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "organizationManagerDeleteCancel": // 企業管理－組織管理－削除－キャンセル
      addIspLog(
        {
          scene_id: apiLog.sceneId.organizationManagement,
          sub_scene_id: apiLog.subSceneId.delete,
          sub_scene2_id: apiLog.subSceneId.cancel,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "companyUserListClick": // 企業管理－詳細－企業ユーザー管理押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.companyManagement,
          sub_scene_id: apiLog.subSceneId.detail,
          sub_scene2_id: apiLog.subSceneId.companyUserManagement,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "companyUserAddClick": // 企業管理－企業ユーザー管理－ユーザ－新規登録
      addIspLog(
        {
          scene_id: apiLog.sceneId.companyUserManagement,
          sub_scene_id: apiLog.subSceneId.new,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "companyUserAddCancel": // 企業管理－企業ユーザー管理－ユーザ－新規登録－キャンセル
      addIspLog(
        {
          scene_id: apiLog.sceneId.companyUserManagement,
          sub_scene_id: apiLog.subSceneId.new,
          sub_scene2_id: apiLog.subSceneId.cancel,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "companyUserAddOrganizationSelect": // 企業管理－企業ユーザー管理－ユーザ－新規登録－組織指定
      addIspLog(
        {
          scene_id: apiLog.sceneId.companyUserManagement,
          sub_scene_id: apiLog.subSceneId.new,
          sub_scene2_id: apiLog.subSceneId.organization,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "companyUserAddOrganizationList": // 企業管理－企業ユーザー管理－ユーザ－新規登録－組織一覧
      addIspLog(
        {
          scene_id: apiLog.sceneId.companyUserManagement,
          sub_scene_id: apiLog.subSceneId.new,
          sub_scene2_id: apiLog.subSceneId.organization,
          action_id: apiLog.actionId.search,
        },
        callback
      );
      break;
    case "companyUserAddOrganizationAddClick": // 企業管理－企業ユーザー管理－ユーザ－新規登録－組織一覧－選択
      addIspLog(
        {
          scene_id: apiLog.sceneId.companyUserManagement,
          sub_scene_id: apiLog.subSceneId.new,
          sub_scene2_id: apiLog.subSceneId.organization,
          action_id: apiLog.actionId.select,
        },
        callback
      );
      break;
    case "companyUserEditClick": // 企業管理－企業ユーザー管理ーユーザー－変更
      addIspLog(
        {
          scene_id: apiLog.sceneId.companyUserManagement,
          sub_scene_id: apiLog.subSceneId.edit,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "companyUserEditCancel": // 企業管理－企業ユーザー管理－ユーザ－編集－キャンセル
      addIspLog(
        {
          scene_id: apiLog.sceneId.companyUserManagement,
          sub_scene_id: apiLog.subSceneId.edit,
          sub_scene2_id: apiLog.subSceneId.cancel,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "companyUserEditOrganizationSelect": // 企業管理－企業ユーザー管理－ユーザ－編集－組織指定
      addIspLog(
        {
          scene_id: apiLog.sceneId.companyUserManagement,
          sub_scene_id: apiLog.subSceneId.edit,
          sub_scene2_id: apiLog.subSceneId.organization,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "companyUserEditOrganizationList": // 企業管理－企業ユーザー管理－ユーザ－編集－組織一覧
      addIspLog(
        {
          scene_id: apiLog.sceneId.companyUserManagement,
          sub_scene_id: apiLog.subSceneId.edit,
          sub_scene2_id: apiLog.subSceneId.organization,
          action_id: apiLog.actionId.search,
        },
        callback
      );
      break;
    case "companyUserEditOrganizationAddClick": // 企業管理－企業ユーザー管理－ユーザ－編集－組織一覧－選択
      addIspLog(
        {
          scene_id: apiLog.sceneId.companyUserManagement,
          sub_scene_id: apiLog.subSceneId.edit,
          sub_scene2_id: apiLog.subSceneId.organization,
          action_id: apiLog.actionId.select,
        },
        callback
      );
      break;
    case "ispUserListClick": // M-LOOPユーザー管理
      addIspLog(
        {
          scene_id: apiLog.sceneId.ispUserManagement,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "ispUserAddClick": // M-LOOPユーザー管理－M-LOOPユーザー登録押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.ispUserManagement,
          sub_scene_id: apiLog.subSceneId.new,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "ispUserAddCancel": // M-LOOPユーザー管理－M-LOOPユーザー登録－キャンセル
      addIspLog(
        {
          scene_id: apiLog.sceneId.ispUserManagement,
          sub_scene_id: apiLog.subSceneId.new,
          sub_scene2_id: apiLog.subSceneId.cancel,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "ispUserEditClick": // M-LOOPユーザー管理－一覧－変更押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.ispUserManagement,
          sub_scene_id: apiLog.subSceneId.edit,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "ispUserEditCancel": // M-LOOPユーザー管理－M-LOOPユーザー編集－キャンセル
      addIspLog(
        {
          scene_id: apiLog.sceneId.ispUserManagement,
          sub_scene_id: apiLog.subSceneId.edit,
          sub_scene2_id: apiLog.subSceneId.cancel,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "informationClick": // インフォメーション管理－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "informationCreateClick": // インフォメーション管理－新規作成－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.new,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "informationCreateCheckClick": // インフォメーション管理－新規作成－確認押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.new,
          sub_scene3_id: apiLog.subSceneId.confirm,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "informationCreateAddClick": // インフォメーション管理－新規作成－登録押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.new,
          sub_scene3_id: apiLog.subSceneId.entry,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "informationCreateCancelClick": // インフォメーション管理－新規作成－キャンセル押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.new,
          sub_scene3_id: apiLog.subSceneId.cancel,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "informationCreateNotificationClick": // インフォメーション管理－新規作成－通知先設定押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.new,
          sub_scene3_id: apiLog.subSceneId.notificationTargetSetting,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "informationNotificationUserSelect": // インフォメーション管理－通知先設定－通知先ユーザー選択
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.notificationTargetSetting,
          sub_scene3_id: apiLog.subSceneId.notificationUser,
          action_id: apiLog.actionId.select,
        },
        callback
      );
      break;
    case "informationNotificationTemplateClick": // インフォメーション管理ー通知先設定－テンプレートから指定－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.notificationTargetSetting,
          sub_scene3_id: apiLog.subSceneId.templateSelect,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "informationNotificationTemplateSelect": // インフォメーション管理ー通知先設定－テンプレートから指定－選択
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.notificationTargetSetting,
          sub_scene3_id: apiLog.subSceneId.templateSelect,
          action_id: apiLog.actionId.select,
        },
        callback
      );
      break;
    case "informationNotificationEditClick": // インフォメーション管理ー通知先設定－テンプレートから指定－編集押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.notificationTargetSetting,
          sub_scene3_id: apiLog.subSceneId.templateSelect,
          sub_scene4_id: apiLog.subSceneId.edit,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "informationNotificationEditUpdateClick": // インフォメーション管理ー通知先設定－テンプレートから指定－編集－更新押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.notificationTargetSetting,
          sub_scene3_id: apiLog.subSceneId.templateSelect,
          sub_scene4_id: apiLog.subSceneId.update,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "informationNotificationEditDeleteClick": // インフォメーション管理ー通知先設定－テンプレートから指定－編集－削除押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.notificationTargetSetting,
          sub_scene3_id: apiLog.subSceneId.templateSelect,
          sub_scene4_id: apiLog.subSceneId.delete,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "informationNotificationNewSaveClick": // インフォメーション管理ー通知先設定－新規保存押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.notificationTargetSetting,
          sub_scene3_id: apiLog.subSceneId.template,
          sub_scene4_id: apiLog.subSceneId.new,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "informationNotificationOverwritingSaveClick": // インフォメーション管理ー通知先設定－上書保存－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.notificationTargetSetting,
          sub_scene3_id: apiLog.subSceneId.template,
          sub_scene4_id: apiLog.subSceneId.overwritingSave,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "informationNotificationCsvImportClick": // インフォメーション管理ー通知先設定－csv取込－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.notificationTargetSetting,
          sub_scene3_id: apiLog.subSceneId.csvImport,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "informationListDetailClick": // インフォメーション管理－一覧取得－詳細押下（ダブルクリック）
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.detail,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "informationListDetailDeleteClick": // インフォメーション管理－一覧取得－詳細－削除押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.detail,
          sub_scene3_id: apiLog.subSceneId.delete,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "informationListDetailDeleteCancel": // インフォメーション管理－一覧取得－詳細－削除－キャンセル
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.detail,
          sub_scene3_id: apiLog.subSceneId.delete,
          sub_scene4_id: apiLog.subSceneId.cancel,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "informationListDetailCopyClick": // インフォメーション管理－一覧取得－詳細－複製押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.detail,
          sub_scene3_id: apiLog.subSceneId.copy,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "informationListDetailEditClick": // インフォメーション管理－一覧取得－詳細－編集押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.detail,
          sub_scene3_id: apiLog.subSceneId.edit,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "informationListDetailEditCancel": // インフォメーション管理－一覧取得－詳細－編集－キャンセル
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.detail,
          sub_scene3_id: apiLog.subSceneId.edit,
          sub_scene4_id: apiLog.subSceneId.cancel,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "informationListDetailDeliveryClick": // インフォメーション管理－一覧取得－詳細－配信先再指定押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.informationManagement,
          sub_scene2_id: apiLog.subSceneId.detail,
          sub_scene3_id: apiLog.subSceneId.reSelect,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "logManagerClick": // ログ管理－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.logManagement,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "logManagerCompanyOperationLogClick": // ログ管理－企業操作ログ－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.logManagement,
          sub_scene2_id: apiLog.subSceneId.personalSetting,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "logManagerDlClick": // ログ管理－DL－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.logManagement,
          sub_scene2_id: apiLog.subSceneId.personalSetting,
          sub_scene3_id: apiLog.subSceneId.dl,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "organizationMargeSelectOrgClick": // 組織管理-編集-合併-組織選択押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.organizationManagement,
          sub_scene2_id: apiLog.subSceneId.edit,
          sub_scene3_id: apiLog.subSceneId.marge,
          sub_scene4_id: apiLog.subSceneId.organization,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "organizationMargeList": // 組織管理-編集-合併-組織一覧
      addIspLog(
        {
          scene_id: apiLog.sceneId.organizationManagement,
          sub_scene2_id: apiLog.subSceneId.edit,
          sub_scene3_id: apiLog.subSceneId.marge,
          sub_scene4_id: apiLog.subSceneId.organization,
          action_id: apiLog.actionId.search,
        },
        callback
      );
      break;
    case "organizationMargeListSelect": // 組織管理-編集-合併-組織一覧-選択
      addIspLog(
        {
          scene_id: apiLog.sceneId.organizationManagement,
          sub_scene2_id: apiLog.subSceneId.edit,
          sub_scene3_id: apiLog.subSceneId.marge,
          sub_scene4_id: apiLog.subSceneId.organization,
          action_id: apiLog.actionId.select,
        },
        callback
      );
      break;
    case "organizationMargeClick": // 組織管理-編集-合併-変更押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.organizationManagement,
          sub_scene2_id: apiLog.subSceneId.edit,
          sub_scene3_id: apiLog.subSceneId.marge,
          sub_scene4_id: apiLog.subSceneId.confirm,
          sub_scene5_id: apiLog.subSceneId.modify,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "organizationMargeCancel": // 組織管理-編集-合併-キャンセル押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.organizationManagement,
          sub_scene2_id: apiLog.subSceneId.edit,
          sub_scene3_id: apiLog.subSceneId.marge,
          sub_scene4_id: apiLog.subSceneId.confirm,
          sub_scene5_id: apiLog.subSceneId.cancel,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "authManagerClick": // 権限マスタ管理－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.authManagement,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "authManagerAddClick": // 権限マスタ管理－新規登録－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.authManagement,
          sub_scene_id: apiLog.subSceneId.new,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "authManagerAddDropFile": // 権限マスタ管理－新規登録－ファイルドロップ（ファイル選択）
      addIspLog(
        {
          scene_id: apiLog.sceneId.authManagement,
          sub_scene_id: apiLog.subSceneId.new,
          sub_scene2_id: apiLog.subSceneId.file,
          action_id: apiLog.actionId.drop,
        },
        callback
      );
      break;
    case "authManagerAddPostClick": // 権限マスタ管理－新規登録－登録－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.authManagement,
          sub_scene_id: apiLog.subSceneId.new,
          sub_scene2_id: apiLog.subSceneId.entry,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "authManagerAddCancel": // 権限マスタ管理－新規登録－閉じる（キャンセル）－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.authManagement,
          sub_scene_id: apiLog.subSceneId.new,
          sub_scene2_id: apiLog.subSceneId.cancel,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "authManagerDetailClick": // 権限マスタ管理－一覧取得－詳細－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.authManagement,
          sub_scene_id: apiLog.subSceneId.detail,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "authManagerDetailCloseClick": // 権限マスタ管理－一覧取得－詳細－閉じる－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.authManagement,
          sub_scene_id: apiLog.subSceneId.detail,
          sub_scene2_id: apiLog.subSceneId.cancel,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "authManagerDetailEditClick": // 権限マスタ管理－一覧取得－詳細－編集－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.authManagement,
          sub_scene_id: apiLog.subSceneId.detail,
          sub_scene2_id: apiLog.subSceneId.edit,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "authManagerDetailEditFormClick": // 権限マスタ管理－一覧取得－詳細－編集－フォーム入力－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.authManagement,
          sub_scene_id: apiLog.subSceneId.detail,
          sub_scene2_id: apiLog.subSceneId.edit,
          sub_scene3_id: apiLog.subSceneId.form,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "authManagerDetailEditDirectClick": // 権限マスタ管理－一覧取得－詳細－編集－直接入力－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.authManagement,
          sub_scene_id: apiLog.subSceneId.detail,
          sub_scene2_id: apiLog.subSceneId.edit,
          sub_scene3_id: apiLog.subSceneId.direct,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "authManagerDetailEditReturnClick": // 権限マスタ管理－一覧取得－詳細－編集－戻る－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.authManagement,
          sub_scene_id: apiLog.subSceneId.detail,
          sub_scene2_id: apiLog.subSceneId.edit,
          sub_scene3_id: apiLog.subSceneId.return,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "authManagerDetailEditCancelClick": // 権限マスタ管理－一覧取得－詳細－編集－戻る－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.authManagement,
          sub_scene_id: apiLog.subSceneId.detail,
          sub_scene2_id: apiLog.subSceneId.edit,
          sub_scene3_id: apiLog.subSceneId.cancel,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "authManagerDetailEditPostClick": // 権限マスタ管理－一覧取得－詳細－編集－戻る－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.authManagement,
          sub_scene_id: apiLog.subSceneId.detail,
          sub_scene2_id: apiLog.subSceneId.edit,
          sub_scene3_id: apiLog.subSceneId.update,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "requestManagerClick": // 依頼設定－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.requestSetting,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "requestManagerPersonSettingClick": // 依頼設定－個人宛－設定－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.requestSetting,
          sub_scene_id: apiLog.subSceneId.persons,
          sub_scene2_id: apiLog.subSceneId.setting,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "requestManagerPersonSettingRegisterClick": // 依頼設定－個人宛－設定－登録－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.requestSetting,
          sub_scene_id: apiLog.subSceneId.persons,
          sub_scene2_id: apiLog.subSceneId.setting,
          sub_scene3_id: apiLog.subSceneId.entry,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "requestManagerPersonSettingCancelClick": // 依頼設定－個人宛－設定－キャンセル－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.requestSetting,
          sub_scene_id: apiLog.subSceneId.persons,
          sub_scene2_id: apiLog.subSceneId.setting,
          sub_scene3_id: apiLog.subSceneId.cancel,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "requestManagerOrganizationSettingClick": // 依頼設定－組織宛－設定－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.requestSetting,
          sub_scene_id: apiLog.subSceneId.organizations,
          sub_scene2_id: apiLog.subSceneId.setting,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "requestManagerOrganizationSettingRegisterClick": // 依頼設定－組織宛－設定－登録－実行
      addIspLog(
        {
          scene_id: apiLog.sceneId.requestSetting,
          sub_scene_id: apiLog.subSceneId.organizations,
          sub_scene2_id: apiLog.subSceneId.setting,
          sub_scene3_id: apiLog.subSceneId.entry,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "requestManagerOrganizationSettingCancelClick": // 依頼設定－組織宛－設定－キャンセル－押下
      addIspLog(
        {
          scene_id: apiLog.sceneId.requestSetting,
          sub_scene_id: apiLog.subSceneId.organizations,
          sub_scene2_id: apiLog.subSceneId.setting,
          sub_scene3_id: apiLog.subSceneId.cancel,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
  }
};
