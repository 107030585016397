import con from '../../../../../../connection';

const path = '/files';

const files = (url: string) => (file_id?: string) => ({
  // 依頼ファイルダウンロード
  get: (param: {
    file_ids: string[],
  }) => con.getFile<{
    header: {
      status: string,
      status_code: number,
      messages: { message: string },
    },
  }>(`${url}${path}`, param),
  // 依頼ファイルアップロード
  post: (param: {
    Filedata: File,
  }) => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: { message: string },
    },
    body: {
      data: { file_id: string },
    }
  }>(`${url}${path}`, param),
  // 依頼ファイル削除
  delete: () => con.delete<{
    header: {
      status: string,
      status_code: number,
      messages: { message: string },
    },
  }>(`${url}${path}/${file_id || ''}`, {}),
});

export default files;
