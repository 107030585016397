import con from "../../../../connection";

const path = '/authorities';

export type GetResponseCompanyAuthorities = {
  /** システム名 */
  system: string,
  /** 説明 */
  description: string,
  /** 各種権限 */
  authorities: {
    /** 機能 */
    authority: string,
    /** 説明 */
    description: string,
  }[]
}

export type GetResponseCompanyDetailAuthorityDataEntity = {
  id: number,
  system: string,
  value: boolean,
  authorities: {
    id: number,
    authority: string,
    value: boolean,
  }[],
}

const authorities = (url: string) => () => {
  return {
    get: () => con.get<{}>(`${url}${path}`, {}),
    getDetail: (id: string) => con.get<{}>(`${url}/${id}${path}`, {})
  };
};

export default authorities;
