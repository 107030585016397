import con from '../../../connection';
import { ApiGetIconsResponse } from './api-get-icons.response';

const path = '/icon';

const icon = (url: string) => () => {
  return {
    get: () => con.get<ApiGetIconsResponse>(url + path, {}),
    post: (
      param:{
        Filedata: string,
          }
    ) =>
      con.post<{
        header: {
          status: string;
          status_code: number;
          messages: string[];
        };
        body: {
          data: []
        };
      }>(
        url + path, param),
    delete: () => con.delete<{
      header: {
        status: string;
        status_code: number;
        messages: string[];
      },
      body: {
        data: []
      },
    }>(
      `${url}${path}`, {}
    )
  };
};

export default icon;
