import { SelectData } from "../../components/pages/log/operation-log";
import { SelectInfoData } from '../../components/ui/select/react-select';

type RequestType = string | SelectInfoData[];

type ListParam = Partial<{
  pref_ids: SelectInfoData[];
  company_codes: SelectInfoData[];
  organization_codes: SelectInfoData[];
  created_at_from: string;
  member_created_at_from: string;
  industry_ids: SelectInfoData[];
  occupation_ids: SelectInfoData[];
  users: SelectInfoData[];
  roles: SelectInfoData[];
  is_cadect1: string;
  is_cadect2: string;
  is_cadect3: string;
  is_cadect4: string;
}>


const numberType = ['pref_ids'];
export class InfomationModel {
  static listParam(param: ListParam) {
   return this.createParam(param);
  }

  static createParam(param: {[key in string]: RequestType}) {
    const form: {[key in string]: string } = {};
    const keys = Object.keys(param);
    for (const key of keys) {
      if (!param[key]) continue;
      const _key = key as keyof {[key in string]: RequestType};
      if (Array.isArray(param[_key])) {
        if (!param[_key].length) continue;
        const values = (param[_key] as any[]).map((v) => v.value)
        values.forEach((v, i) => {
          form[`${_key}[${i}]`] = numberType.includes(key) ? Number(v) : v;
        })
      } else if (typeof param[key] === 'string'){
        form[key] = param[key] as string;
      }
    }
    return form;
  }
}

// pref_ids
// company_codes
// created_at_from
// created_at_to
// industry_ids
// occupation_ids
// member_created_at_from
// member_created_at_to
// roles
// is_cadect1
// is_cadect2
// is_cadect3
// is_cadect4
// building_use