import icon_0 from '../../../assets/images/common/icon_enterprise.svg';
import icon_1 from '../../../assets/images/common/icon_uesr.svg';
import icon_2 from '../../../assets/images/common/icon_property.svg';

type TotalNumTipProps = {
  title: string,
  num: number,
  unit: string,
  icon: number,
};

export const TotalNumTip = (props: TotalNumTipProps) => {
  const { title, num, unit, icon } = props;
  const src = icon === 0 ? icon_0 : icon === 1 ? icon_1 : icon_2;
  return (
    <div className="tip_wraper tip_nums">
      <div className="tip box_shadow">
        <img src={src} alt="{title}" />
        <div className="text_box">
          <h3>{title}</h3>
          <div className="count">{num.toLocaleString()}<span>{unit}</span></div>
        </div>
      </div>
    </div>
  );
};
